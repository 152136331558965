<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
		<mat-tab-group dynamicHeight mat-align-tabs="center">
		  <mat-tab label="Schedule Task List">
			<ng-template matTabContent>
				<div class="row">				
					<div class="col-lg-4 ml-auto" >
					  <a class="btn btn-fill btn-rose " style="float: right;" (click)="add()">New Schedule</a>
					</div>
					<div class="col-md-12">
						<div class="card">
						  <div class="card-header card-header-primary card-header-icon">
							<div class="card-icon">
							  <i class="material-icons">assignment</i>
							</div>
							  <h4 class="card-title">Schedule Task List</h4>
						  </div>
							<div class="card-body">
								<div class="toolbar">
								   <div class="row form-group">
										<div class="col-sm-3">
											<select class="form-control" [(ngModel)]="user_id" (ngModelChange)="filter()">
												<option value="">All user</option>
												<option *ngFor="let user of userList" [value]="user.id">{{ user.name }}</option>
											</select>
										</div>
										<div class="col-sm-3">
											<select class="form-control" [(ngModel)]="status" (ngModelChange)="filter()">
												<option value="">All Status</option>
												<option value="ongoing">On Going</option>
											</select>
										</div>
										<div class="offset-sm-2 col-md-4">
											<input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." >
										</div>
									</div>
								</div>
								<div class="material-datatables">
								  <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
										<thead>
											<tr>
											  <th>Date</th>
											  <th>P/O No </th>
											  <th>Customer</th>
											  <th>Address</th>
											  <th>Amount</th>
											  <th>Service By</th>
											  <th>Installer By</th>
											  <th>Action</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngIf="!followUpList">
												<td colspan="7" class="text-center">Loading follow up...</td>
											</tr>
											<tr *ngIf="followUpList && followUpList.length == 0 && !haveFilter()">
												<td colspan="7" class="text-center">No scheduled follow up.</td>
											</tr>
											<tr *ngIf="followUpList && haveFilter() && filteredList.length == 0">
												<td colspan="7" class="text-center">No matched follow up.</td>
											</tr>
											<tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn">
												<td class="text-center">{{ item.date | date: 'EEEE' }}<br />{{ item.date | date: 'dd-MM-yy' }}</td>
												<td class="text-center">{{ item.quotation?.name }}<br />{{ item.quotation?.created_at | date: 'dd-MM-yy' }}</td>
												<td class="text-center">{{ item.quotation?.client?.company_name || item.quotation?.client?.person }}<br />{{ item.client_remark }}</td>
												<td>{{ item.quotation?.client?.full_address }}<br />{{ item.address_remark }}</td>
												<td class="text-center">{{ (item.quotation?.confirmed?.amount | currency: "RM ") || "Quotation is Pending" }}<br /><span *ngIf="item.quotation?.paid">Deposit: {{ item.quotation?.confirmed?.paid_amount | currency: "RM " }}</span></td>
												<td class="text-center">{{ item.quotation?.user?.name || "Not Assigned" }}<br />{{ item.user_remark }}</td>
												<td class="text-center">{{ item.installer?.name || "Not Assigned" }}</td>
												<td>
												  <a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
												  <a class="btn btn-link btn-danger btn-just-icon remove"><i [class.disabled]="item.deleting" class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
												</td>
											</tr>
										</tbody>
									</table>
								</div>								
								<div class="pull-right" style="margin-top: 15px">
									<pagination-controls (pageChange)="p = $event"></pagination-controls>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		  </mat-tab>
		  <mat-tab label="Schedule Calendar">
			<ng-template matTabContent>
				<div class="row">				
				  <div class="col-md-10 ml-auto mr-auto">
					<div class="card card-calendar">
					  <div class="card-body">
						<full-calendar #calendar [options]="calendarOptions"></full-calendar>
					  </div>
					</div>
				  </div>
				</div>
			</ng-template>
		  </mat-tab>
		  
		</mat-tab-group>
    </div>
</div>
