import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  key;

  translations = {};
  
  invoice;
  settingList = {};
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService,
	private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('invoice');
	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  initSetting(regionId) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SETTING_GET_MULTI), {
			keys: [
				'company_name',
				'company_email',
				'company_website',
				'company_address',
				'company_fax',
				'company_sst',
				'company_logo',
				'company_reg_no',
				'company_phone',
				'company_chop',
				'company_chop_text',
				'note',
				'deposit_percentage',
			],
			region_id: regionId
		}
	).then((settingList : any) => {
		this.settingList = settingList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
  }

  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.INVOICE_GET, [this.key])
		).then((invoice : any) => {
			this.invoice = invoice;
			
			this.initSetting(this.getVersion()?.region_id);
		}, err => {
			Swal.fire("Invoice Viewing", this.apiService.handleStatusException(err), 'error');
		});
	}
  }
 
  getVersion() {
	  return this.invoice?.quotation?.confirmed;
  }
  
  getSanitizerURL(path) {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(APIService.STORAGE + path);
  }
  
  getSanitizerHTML(html) {
	  return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  
  getLines(text) {
	  return text.split("\n");
  }
  
  remove() {
	Swal.fire({
		title: "Delete Invoice",
		text: "Do you want to delete " + this.invoice.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.isConfirmed) {
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.INVOICE_DELETE), {
					id: this.invoice.id
				}
			).then((res : any) => {
				if(this.invoice.details) {
				} else {
					this.router.navigate(['invoice']);
				}
			}, err => {
				Swal.fire("Error on Invoice Delete", this.apiService.handleStatusException(err), 'error');
			});
		}
	});
  }
  
  pdf() {
	  if(this.invoice) {
		window.open(APIService.INVOICE_PDF + "?id=" + this.invoice.id, "_blank");
	  }
  }
  
  share() {
	  if(this.invoice) {
		window.open(APIService.INVOICE_SHARE + "?id=" + this.invoice.id, "_blank");
	  }
  }
  
  receipt() {
	this.router.navigate(['receipt/add', { i_uid: this.invoice.id }]);
  }
  
  editName() {
	Swal.fire({
		title: "Edit Invoice No",
		input: "text",
		inputValue: this.invoice.name,
		inputValidator: (value) => {
			if (!value) {
				return "Please enter invoice no";
			}
		},
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		focusCancel: true,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
	}).then(result => {
		if(result.isConfirmed) {
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.INVOICE_UPDATE), {
					id: this.invoice.id,
					name: result.value
				}
			).then((res : any) => {
				this.invoice.name = res.name;
			}, err => {
				Swal.fire("Update invoice no", this.apiService.handleStatusException(err), 'error');
			});
		}
	});
  }
}
