<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card ">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">mail_outline</i>
              </div>
              <h4 class="card-title">Setting 设置</h4>
            </div>
            <div class="card-body">
              <mat-tab-group dynamicHeight mat-align-tabs="center" (selectedTabChange)="onTabChanged($event)">
                <mat-tab *ngFor="let region of regionList" [label]="region.name">
                  <ng-template matTabContent>
                      <div class="row" style="margin: 0 20px 0 0">
                          <ng-container *ngFor="let setting of templateList">
                              <ng-container [ngSwitch]="setting.type">
                                  <div *ngSwitchCase="'text'" class="col-sm-12">
                                      <ng-container [ngTemplateOutlet]="formGroup1" [ngTemplateOutletContext]="{ setting: setting }"></ng-container>
                                  </div>
                                  <div *ngSwitchCase="'number'" class="col-sm-12">
                                      <ng-container [ngTemplateOutlet]="formGroup1" [ngTemplateOutletContext]="{ setting: setting }"></ng-container>
                                  </div>
                                  <div *ngSwitchCase="'textarea'" class="col-sm-12">
                                      <div class="form-group">
                                          <mat-form-field class="example-full-width">
                                              <textarea matInput [placeholder]="setting.name" rows="3" (change)="updateSetting($event, setting)" [disabled]="setting.updating" class="form-control" [innerHTML]="getRegionSetting(setting.key)"></textarea>
                                          </mat-form-field>
                                      </div>
                                  </div>
                                  <div *ngSwitchCase="'image'" class="col-sm-12">
                                      <p>{{ setting.name }}</p>
                                      <div class="fileinput text-center {{ getRegionSetting(setting.key) ? 'fileinput-exists' : 'fileinput-new' }}" data-provides="fileinput">
                                          <div *ngIf="setting.updating" class="alert alert-warning">
                                              <span class="text-white">Uploading...</span>
                                          </div>
                                          <div class="fileinput-new thumbnail">
                                              <img src="./assets/img/image_placeholder.jpg" alt="...">
                                          </div>
                                          <div class="fileinput-preview fileinput-exists thumbnail">
                                              <img *ngIf="getRegionSetting(setting.key)" [src]="getSanitizerURL(getRegionSetting(setting.key))" alt="Uploaded Image" />
                                          </div>
                                          <div>
                                              <span class="btn btn-rose btn-round btn-file" [class.disabled]="setting.updating">
                                                  <span class="fileinput-new">Select Image</span>
                                                  <span class="fileinput-exists">Change</span>
                                                  <input type="file" (change)="onFileChange($event, setting)" [disabled]="setting.updating" accept="image/*" />
                                              </span>
                                              <a [href]="'#' + setting.key" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="remove(setting)" [class.disabled]="setting.updating"><i class="fa fa-times"></i> Remove</a>
                                          </div>
                                      </div>
                                  </div>
                                  <div *ngSwitchDefault class="col-sm-12">
                                      <div class="form-group">
                                            <input [placeholder]="setting.name"  [type]="setting.type" (change)="updateSetting($event, setting)" [disabled]="setting.updating" [value]="getRegionSetting(setting.key)" class="form-control" />
                                      </div>
                                  </div>								
                                  <ng-template #formGroup1 let-setting="setting">
                                      <div class="form-group">
                                          <mat-form-field class="example-full-width">
                                              <input matInput [placeholder]="setting.name"  [type]="setting.type" (change)="updateSetting($event, setting)" [disabled]="setting.updating" [value]="getRegionSetting(setting.key)" class="form-control" />
                                            </mat-form-field>
                                      </div>
                                  </ng-template>
                              </ng-container>
                          </ng-container>
                      </div>
                  </ng-template>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  