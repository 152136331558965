<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card ">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ key ? 'Edit Measurement' : 'Add Measurement' }}</h4>
                </div>
                <div class="card-body">
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-8">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Name" maxlength="255" type="text" formControlName="name" />						
                              <mat-error *ngIf="form.controls.name.hasError('required')">Name is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Symbol" maxlength="255" type="text" formControlName="short" />						
                              <mat-error *ngIf="form.controls.short.hasError('required')">Symbol is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Updating' : 'Update') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  