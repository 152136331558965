import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  filteredList = [];
  invoiceList;
  term = "";
  p;
  status = [];
  
  translations = {};
  id;
  name;
  
  subscriber;
  params;
  
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
	this.aroute.params
	.subscribe(params => {
		this.params = params;
		switch (this.params["status"]) {
			case "pending":
				this.status = ["Deposit Pending"];
				break;
			case "not_paid":
				this.status = ["Deposit Pending", "Deposit Received"];
				break;
			default:
				this.status = [];
		}
		this.stopObs();
	
		this.startObs();
	});
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getInvoicesObs({ q_id: this.params['q_uid'] }).subscribe(invoiceList => {
		this.invoiceList = invoiceList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	if (this.subscriber) {
	  this.subscriber.unsubscribe();
	}
  }
  
  haveFilter() {
	return this.status.length > 0 || this.term;
  }

  filter() {
	  if(!this.invoiceList) {
		  return;
	  }
	  
	  if(!this.haveFilter()) {
		  this.filteredList = this.invoiceList;
		  return;
	  }
	  
	  const term = this.term?.toLowerCase();
	  const result = [];
	  
	  this.invoiceList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) === -1) {
			return;
		  }
		  
		  if(item.amount && item.amount === parseFloat(term)) {
			return;
		  }
		  
		  if(item.status && this.status.indexOf(item.status) === -1) {
			return;
		  }
		  
		  if(item.created_at && new Date(item.created_at).toString().indexOf(term) === -1) {
			return;
		  }
		  
		  result.push(item);
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['invoice/show']);
  }
  
  edit(index, item) {
    this.router.navigate(['invoice/show', { uid: item.id }]);
  }
  
  view(index, item) {
    this.router.navigate(['invoice/show', { uid: item.id }]);
  }
  
  more(index, item) {
    this.router.navigate(['receipt', { i_uid: item.id }]);
  }
  
  remove(index, item) {
	Swal.fire({
		title: "Delete Invoice",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.INVOICE_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.invoiceList.splice(index, 1);
				
				this.filter();
			}, err => {
				item.deleting = false;
				Swal.fire("Error on Invoice Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	// this.afs.collection('confinement_womens').doc(item.key).update({
		// 'status': value
	// });
  }
	
  trackByFn(index, item) {
	return item.id;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.INVOICE_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			Swal.fire("Error on Invoice Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}