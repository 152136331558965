<div class="main-content" style="margin-top: 0px">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 ml-auto mr-auto" style=" 
				width: 100%; 
				overflow-x: scroll;">
				<div>
					<div class="row" style="border-bottom: 2px solid #000">
						<div class="col-sm-12">
							<h1 class="address-header">{{ settingList['company_name'] }} <span class="company-no">{{ settingList['company_reg_no'] }}</span></h1>
						</div>
						<div class="col-9 col-sm-10">
							<div class="address">
								<p style="white-space: pre-line;">{{ settingList['company_address'] }}</p>
								<p>Website: {{ settingList['company_website'] }}</p>
								<p>Email {{ settingList['company_email'] }}</p>
								<div class="row">
									<div class="col-4 detail" style="padding-left: 15px">
										<p>Tel: {{ settingList['company_phone'] }}</p>
									</div>
									<div class="col-4 detail">
										<p>Fax: {{ settingList['company_fax'] }}</p>
									</div>
									<div class="col-4 detail" style="padding-right: 0">
										<p>SST ID: {{ settingList['company_sst'] }}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="col-3 col-sm-2" style="position: relative; ">
							<img *ngIf="settingList['company_logo']" [src]="getSanitizerURL(settingList['company_logo'])" style="position: absolute; bottom: 0; right: 10px;" width="80%">
						</div>
					</div>
					<div class="row">
						<div class="offset-2 col-6">
							<select *ngIf="quotation" class="form-control page-header" [ngModel]="current_version_id" (ngModelChange)="changeQuotation($event)">
								<option *ngFor="let version of quotation.versions" [value]="version.id">{{ quotation.name + "-v" + version.version }}</option>
							</select>
						</div>
						<div class="col-4 text-right quotation-no">
							<p>Quotation No: {{ quotation ? quotation.name : "Loading..." }}</p>
						</div>
					</div>

					<div class="row no-margin"  style="border-bottom: 2px solid #000">
						<div class="col-1" style="padding-left: 15px; padding-right: 0">
							<p>Company <span style="float: right;">:</span></p>
							<p>Address <span style="float: right;">:</span></p>
							<p>Tel <span style="float: right;">:</span></p>
							<p>Name <span style="float: right;">:</span></p>
							<p>Email <span style="float: right;">:</span></p>
						</div>
						<div class="col-6">
							<p id="comName">{{ quotation && quotation.client ? quotation.client.company_name : "Loading..." }}</p>
								<p id="address">{{ quotation && quotation.client ? quotation.client.address : "Loading..." }}i</p>
								<p id="tel">{{ quotation && quotation.client ? quotation.client.phone : "Loading..." }}</p>
								<p id="name">{{ quotation && quotation.client ? (quotation.client.person || quotation.client.company_name) : "Loading..." }}</p>
								<p id="email">{{ quotation && quotation.client ? quotation.client.email : "Loading..." }}</p>
							</div>
						<div class="col-4" style="padding-right: 15px">
							<p class="text-center">Page 01/01</p>
							<div class="row no-margin">
								<div class="col-5 text-right">
									<p>Date </p>
									<p>Service By</p>
									<p>Term</p>
								</div>
								<div class="col-2 text-center">
									<p>:</p>
									<p>:</p>
									<p>:</p>
								</div>
								<div class="col-5 text-left">
									<p>{{ quotation ? (quotation.created_at | date: 'yyyy-MM-dd') : "Loading..." }}</p>
									<p>{{ quotation && quotation.user ? quotation.user.name : "Loading..." }}</p>
									<p>Cash</p>
								</div>
							</div>
						</div>
					</div>

					<app-standard-quotation-table [quotation]="quotation" [current_version]="getVersion()"></app-standard-quotation-table>

	  				<div class="row term" style="margin-bottom: 30px">
			  			<div class="col-sm-2 col-4">
			  				<p>Term of Payment : </p>
			  			</div>
	  					<div class="col-sm-10 col-8 ">
	  						<p *ngIf="getDeposit(0, settingList['deposit_percentage'] || 60, 'percentage'); let deposit0">{{ deposit0 }}% Deposit Upon Confirmation Order.<a class="btn btn-link btn-info btn-just-icon edit" style="display: inline;"><i class="material-icons" (click)="editDeposit(0)" title="Edit Deposit Percentage">edit</i></a><a class="btn btn-link btn-info btn-just-icon edit" style="display: inline; margin-left: 10px;"><i class="material-icons" (click)="addDeposit()" title="Add Another Deposit Date">add</i></a></p>
							<ng-container *ngFor="let index of additionalDeposits()">
								<p>{{ getDeposit(index, 0, 'percentage') }}% Deposit before {{ getDeposit(index, settingList['deposit_day'] || 2, 'day') }} day(s) installation date.<a class="btn btn-link btn-info btn-just-icon edit" style="display: inline;"><i class="material-icons" (click)="editDeposit(index)" title="Edit Deposit Percentage">edit</i></a><a class="btn btn-link btn-info btn-just-icon edit" style="display: inline; margin-left: 10px;"><i class="material-icons" (click)="removeDeposit(index)" title="Add Another Deposit Date">remove</i></a></p>
							</ng-container>
							<p>Balance {{ getDepositBalance(settingList['deposit_percentage'] || 60) }}% Upon Install Finish.</p>
	  					</div>
	  				</div>


	  				<div class="row term" style="margin-bottom: 30px">
			  			<div class="col-sm-1 col-3">
			  				<p style="color: red">Noted : </p>
			  			</div>
	  					<div class="col-sm-11 col-9" style="padding-left: 0">
	  						<p class="note"*ngFor="let line of getLines(settingList['note'] || '')" [innerHTML]="getSanitizerHTML(line)"></p>
	  					</div>
	  				</div>

	  				<div class="row " style="margin-bottom: 30px">
			  			<div class="col-6 col-sm-3">
			  				<p style="font-weight: 700">Your Faithfully</p>
			  				<div class="company-chop">
								<img style="max-width: 100%;" *ngIf="settingList['company_chop']" [src]="getSanitizerURL(settingList['company_chop'])" />
							</div>
			  				<p style="font-weight: 550">{{ settingList['company_chop_text'] }}</p>
			  			</div>
	  					<div class="col-6 col-sm-3 ml-auto">
	  						<p style="font-weight: 700">I Accept & Agree</p>
			  				<div class="company-chop"></div>
			  				<p>Company Chop & Sign</p>
	  					</div>
	  				</div>


			  		<div *ngIf="current_version_id" class="row" style="margin-bottom: 40px">
			  			<div class="col-sm-10 ml-auto mr-auto">
			  				<div class="row">
			  					<div class="col-sm-3">
					  				<button type="button" class="btn btn-warning" (click)="pdf()">PDF</button>
					  			</div>
			  					<div class="col-sm-3">
					  				<button type="button" class="btn btn-success" (click)="share()">Share</button>
					  			</div>
								
			  					<div class="col-sm-3">
					  				<button type="button" class="btn btn-primary" (click)="followup()">Follow Up</button>
					  			</div>
								
			  					<div class="col-sm-3">
					  				<button type="button" class="btn btn-primary" (click)="generate()">{{ generating ? 'Processing...' : 'Generate' }}</button>
					  			</div>
			  				</div>
			  			</div>
			  		</div>
												
					<div *ngIf="!(quotation?.confirmed_id && quotation?.confirmed_id)" class="row" style="margin-bottom: 40px">
						<div class="col-sm-10 ml-auto mr-auto">
							<div class="row">
								<div class="col-sm-6">
									<button type="button" class="btn btn-danger" [disabled]="deleting" (click)="deleteVersion()">{{ deleting ? 'Processing...' : 'DELETE VERSION' }}</button>
								</div>		
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
