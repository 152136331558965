import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.css']
})
export class QuotationComponent implements OnInit, OnDestroy {

  filteredList = [];
  quotationList;
  term;
  p;
  
  translations = {};
  id;
  name;
  
  subscriber;
  userList = [];
  user_id = "";
  only_confirmed = "1";
  settingList = {};
  
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.USER_LISTING), { role: "Salesman" }
	).then((userList : any) => {
		this.userList = userList;
	}, err => {
		//
	});
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		regionList.forEach(region => {
			this.loadSetting(region.id);
		});
	}, err => {
		//
	});
	
	this.startObs();
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getQuotationsObs().subscribe(quotationList => {
		this.quotationList = quotationList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	this.subscriber.unsubscribe();
  }
  
  loadSetting(regionId) {
	if(!this.settingList[regionId]) {
		this.settingList[regionId] = {};
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SETTING_GET_MULTI), {
				keys: [
					'deposit_percentage',
				],
				region_id: regionId
			}
		).then((settingList : any) => {
			this.settingList[regionId] = settingList;
		}, err => {
			this.apiService.handleStatusException(err);
		});
	}
  }
  
  haveFilter() {
	  return this.term || this.user_id;
  }

  filter() {
	  if(!this.quotationList) {
		  return;
	  }
	  
	  if(!this.haveFilter()) {
		  this.filteredList = this.quotationList.filter(x => this.only_confirmed === '' || this.only_confirmed === (x.confirmed_id ? "1" : "2"));
		  return;
	  }
	  
	  var term = this.term?.toLowerCase();
	  
	  var result = [];
	  
	  this.quotationList.forEach(item => {
		  if(this.user_id && item.user_id !== this.user_id) {
			return;
		  }
		  
		  if(this.only_confirmed === '' || this.only_confirmed !== (item.confirmed_id ? "1" : "2")) {
			return;
		  }
		  
		  if(term) {
			  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
				result.push(item);
				return;
			  }
			  
			  if(item.amount && item.amount >= parseFloat(term)) {
				result.push(item);
				return;
			  }
			  
			  if(item.status && item.status.toLowerCase().indexOf(term) > -1 
				|| "pending".indexOf(term) > -1) {
				result.push(item);
				return;
			  }
			  
			  if(item.created_at && new Date(item.created_at).toString().indexOf(term) > -1) {
				result.push(item);
				return;
			  }
		  } else {
			result.push(item);
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['quotation/show']);
  }
    
  view(item: any) {
    this.router.navigate(['quotation/show', { uid: item.id }]);
  }
  
  edit(item: any) {
    this.router.navigate(['quotation/show', { uid: item.id }]);
  }
  
  moreSalesOrder(item: any) {
    this.router.navigate(['sales_order', { q_uid: item.id }]);
  }
  
  moreInvoice(item: any) {
    this.router.navigate(['invoice', { q_uid: item.id }]);
  }
  
  moreFollowUp(item: any) {
    this.router.navigate(['follow_up', { q_uid: item.id }]);
  }
  
  remove(index: number, item: any) {
	if(item.deleting) {
		return;
	}
	
	Swal.fire({
		title: "Delete Quotation",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.QUOTATION_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.quotationList.splice(index, 1);
				
				this.filter();
				item.deleting = false;
			}, err => {
				item.deleting = false;
				Swal.fire("Quotation Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	if(!item.updating) {
		item.updating = true;
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_STATUS), {
				id: item.id,
				status: value,
			}
		).then((res : any) => {
			item.updating = false;
		}, err => {
			item.updating = false;
			Swal.fire("Quotation Status Update", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
  
  updatePrimary(item, value) {	
	if(!item.updating) {
		item.updating = true;
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_UPDATE), {
				id: item.id,
				primary_id: value,
			}
		).then((res : any) => {
			item.updating = false;
		}, err => {
			item.updating = false;
			Swal.fire("Quotation Primary Update", this.apiService.handleStatusException(err, true), 'error');
		});		
	}
  }
	
  itemTrackBy(index, item) {
	return item.id;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			this.startObs();
			Swal.fire("Quotation Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
  
  getVersion(item, field = "confirmed_id") {
	   return item.versions.find(x => x.id == item[field]);
  }
  
  getDepositPercentage(version) {
	  return version.deposit_percentage || this.settingList[version.region_id]?.deposit_percentage || 30;
  }
  
  commission(item, recalculate) {
	if(!item.recalculating) {
		item.recalculating = true;
		this.stopObs();
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_COMMISSSION), {
				id: item.id,
				recalculate: recalculate
			}
		).then((res : any) => {
			Swal.fire(recalculate ? "Sales Commission Update" : "Sales Commission Generate",
				recalculate ? "Sales Commission Updated." : "Sales Commission Generated.", 'success');
			this.startObs();
		}, err => {
			this.startObs();
			Swal.fire(recalculate ? "Sales Commission Update" : "Sales Commission Generate", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
