import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './shared/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  constructor(
	private authService: AuthService,
    private router: Router
  ) {
    

  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	  if(this.authService.isLoggedIn()) {
		return true;
	  } else {
		sessionStorage.setItem('route', state.url);
		return this.router.createUrlTree(['login']);
	  }
  }
  
}
