import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizeService {
  
  translation;
  locale;

  constructor() { }
  
  setLocale(locale) {
	  if(locale) {
		localStorage.setItem('locale', locale);
	  } else {
		localStorage.removeItem('locale');
	  }
	  this.locale = locale;
  }
  
  getLocale() {
	  if(!this.locale) {
			this.locale = localStorage.getItem('locale') || 'en';
	  }
	  
	  return this.locale;
  }
    
  setTranslation(translationObj) {
	  if(translationObj) {
	    localStorage.setItem('translation', JSON.stringify(translationObj));
	  } else {
	    localStorage.removeItem('translation');
	  }

	  this.translation = translationObj;
  }
  
  getTranslation(key = null, wDefault = true) {
	  if(!this.translation) {
		  try {
			this.translation = JSON.parse(localStorage.getItem('translation'));
		  } catch {
			if(wDefault) {
				this.translation = {};
			}
		  }
	  }
	  
	  return key ? (this.translation && this.translation[key] ? this.translation[key] : {}) : this.translation;
  }
  
  haveTranslation() {
  }
}
