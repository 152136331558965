import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  token;
  user;
  permission;

  constructor(
  ) {}
  
  isLoggedIn() {
	  return this.getAuthHeader() ? true : false;
  }
  
  getAuthHeader() {
	  if(!this.token) {
		  this.token = localStorage.getItem('auth');
	  }
	  
	  return this.token;
  }
  
  setAuthHeader(auth) {
	  var token = null;
	  
	  if(auth) {
		token = "Bearer " + auth.token;
	    localStorage.setItem('auth', token);
	  } else {
		localStorage.removeItem('auth');	  
	  }
		  
	  this.token = token;
  }
  
  getUser() {
	  if(!this.user) {
		  try {
		  this.user = JSON.parse(localStorage.getItem('user'));
		  } catch {
		  }
	  }
	  
	  return this.user;
  }
  
  setUser(user) {
	  if(user) {
	    localStorage.setItem('user', JSON.stringify(user));
	  } else {
	    localStorage.removeItem('user');
	  }
		  
	  this.user = user;
  }
  
  setPermission(permission) {	  
	  if(permission) {
	    localStorage.setItem('m_perm', JSON.stringify(permission));
	  } else {
	    localStorage.removeItem('m_perm');
	  }

	  this.permission = permission;
  }
  
  getPermission(key = null) {
	  if(!this.permission) {
		  try {
			this.permission = JSON.parse(localStorage.getItem('m_perm'));
		  } catch {
			this.permission = {};
		  }
	  }
	  
	  return key ? this.permission[key] :  this.permission;
  }
}