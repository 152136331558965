import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../../shared/api.service';
import Swal from 'sweetalert2';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-measurement',
  templateUrl: './measurement.component.html',
  styleUrls: ['./measurement.component.css']
})
export class MeasurementComponent implements OnInit, OnDestroy {

  filteredList = [];
  measurementList;
  term;
  p;
  
  translations = {};
  id;
  name;

  loop;

  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    ) { }

  ngOnInit(): void {	
	this.aroute.params
	.subscribe(params => {
		this.id = params['uid'];
		this.name = params['name'];
		
		this.measurementList = null;
		this.filteredList = [];
		
		this.initData();
	
		clearInterval(this.loop);	  
		this.startObs();
    });
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs() {
	this.loop = setInterval(() => this.initData(), 5000);
  }
  
  stopObs() {
	clearInterval(this.loop);	  
  }
  
  initData() {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.PRODUCT_MEASUREMENT_LISTING), {
			id: this.id
		}
	).then((res : any) => {
		this.measurementList = res;
		
		this.filter();
	}, err => {
		this.apiService.handleStatusException(err);
	});
  }

  filter() {
	  if(!this.measurementList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.measurementList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.measurementList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['product/measurement/show']);
  }

  edit(index, item) {
    this.router.navigate(['product/measurement/show', { uid: item.id }]);
  }

  remove(index, item) {
	Swal.fire({
		title: "Delete Product Measurement",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.PRODUCT_MEASUREMENT_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.measurementList.splice(index, 1);
				
				this.filter();
			}, err => {
				item.deleting = false;
				Swal.fire("Error on Product Measurement Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  trackByFn(index, item) {
	return item.id;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_MEASUREMENT_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			Swal.fire("Error on Product Measurement Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
