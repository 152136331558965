<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card ">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ (key ? 'Edit Part for ' : 'Add Part for ') + this.pName + (this.mName ? (" on " + this.mName) : "") }}</h4>
                </div>
                <div class="card-body ">
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-8">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Name" maxlength="255" type="text" formControlName="name" required />						
                              <mat-error *ngIf="form.controls.name.hasError('required')">Name is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Code" maxlength="255" type="text" formControlName="code" />						
                          </mat-form-field>
                      </div>
                   </div>
                   <div class="row" style="margin: 20px 0">
                      <div class="col-sm-12">
                          <mat-form-field class="example-full-width">
                              <textarea matInput placeholder="Description" maxlength="8000" rows="4" formControlName="description"></textarea>
                          </mat-form-field>
                      </div>
                   </div>
                   
                   <div class="row" style="margin: 20px 0">
                      <div class="col-md-12 col-sm-12">
                          <div class="form-check">
                              <mat-checkbox type="checkbox" formControlName="have_price" value="true">
                                  Have Charge(s) on this Part
                              </mat-checkbox>
                          </div>
                      
                          <div *ngIf="form.controls.have_price.value" cdkDropList
                              (cdkDropListDropped)="onDrop($event)">
                              <div formArrayName="prices" *ngFor="let price of form.controls.prices.controls; let i = index"
                                  cdkDrag cdkDragLockAxis="y">
                                  <div class="row" [formGroupName]="i">
                                      <input type="hidden" formControlName="id" />
                                      <div class="col-1">
                                          <div class="drag-handle" cdkDragHandle>
                                              <i class="material-icons">swap_vert</i>
                                          </div>
                                      </div>
                                      <div class="col-2">
                                          <mat-form-field class="example-full-width">
                                              <input matInput maxlength="255" formControlName="name" placeholder="Product Dimension" />
                                              <mat-error *ngIf="price.controls.name.hasError('required')">Product Dimension is required.</mat-error>
                                          </mat-form-field>
                                      </div>
                                      <div class="col-9">
                                          <div formArrayName="specifications" *ngFor="let spec of price.controls.specifications.controls; let j = index" class="row">
                                              <ng-container [formGroupName]="j">	
                                                  <div class="col-3">
                                                      <mat-form-field class="example-full-width">
                                                          <mat-select formControlName="measurement_id">
                                                              <mat-option value="">{{ j == 0 ? 'Not countable' : 'Please select measurement' }}</mat-option>
                                                              <mat-option *ngFor="let measurement of measurementList; trackBy: itemTrackBy" [value]="measurement.id">{{ measurement.name }}</mat-option>
                                                          </mat-select>
                                                      </mat-form-field>
                                                  </div>
                                                  <div class="col-8">
                                                      <div class="row">
                                                          <label *ngIf="regionList && regionList.length == 0">There is no sales region, please add it.</label>
                                                          <ng-container formGroupName="regions">
                                                              <ng-container *ngFor="let regionGroup of spec.controls.regions.controls | keyvalue" [formGroupName]="regionGroup.key">
                                                                  <ng-container *ngIf="getRegion(regionGroup.key); let region">
                                                                      <div class="col-4">
                                                                          <mat-form-field *ngIf="regionGroup.value.controls.amount; let regionPrice" class="example-full-width">
                                                                              <input matInput type="number" formControlName="amount" [placeholder]="region.name + ' Region Price'" />
                                                                              <mat-error *ngIf="regionPrice.hasError('required')">{{ region.name }} Region Price is required.</mat-error>
                                                                          </mat-form-field>
                                                                      </div>
                                                                      <div class="col-4">
                                                                          <mat-form-field class="example-full-width">
                                                                              <mat-select formControlName="discount_id">
                                                                                  <mat-option value="">No discount group on {{ region.name }}</mat-option>
                                                                                  <mat-option *ngFor="let discount of discountList; trackBy: itemTrackBy" [value]="discount.id">{{ 
                                                                                      discount.name + " " + (discount.discount ? " Discount: " + discount.discount + "%" : "") + (discount.addon ? " Addon: " + discount.addon + "%" : "") 
                                                                                  }}</mat-option>
                                                                              </mat-select>
                                                                          </mat-form-field>
                                                                      </div>
                                                                      <div class="col-4">
                                                                          <mat-form-field *ngIf="regionGroup.value.controls.costing_percentage; let field"  class="example-full-width">
                                                                              <input matInput [placeholder]="region.name + ' Costing Percentage (%)'" type="number" formControlName="costing_percentage" />
                                                                              <mat-error *ngIf="field.hasError('required')">{{ region.name }} Costing Percentage (%) is required.</mat-error>
                                                                          </mat-form-field>
                                                                      </div>
                                                                  </ng-container>
                                                              </ng-container>
                                                          </ng-container>
                                                      </div>
                                                  </div>
                                                  <div class="col-1">
                                                      <a *ngIf="form.controls.prices.controls.length > 1 || price.controls.specifications.length > 1" class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons" (click)="removeMeasurement(price.controls.specifications, j, i)" title="Remove">close</i></a>
                                                  </div>
                                              </ng-container>
                                          </div>
                                      </div>
                                  </div>
                                  <div *ngIf="price.controls.specifications.controls[0].controls.measurement_id && price.controls.specifications.controls[0].controls.measurement_id.value && price.controls.specifications.controls.length < measurementList.length" class="row">
                                      <button type="button" (click)="addMeasurement(price.controls.specifications)" class="ml-auto btn btn-fill btn-rose">Add Measurement</button>
                                  </div>
                              </div>
                                      
                              <div class="row" style="margin: 20px 0">
                                  <button type="button" (click)="addSpecification()" class="btn btn-fill btn-rose">Add Price</button>
                              </div>
                          </div>
                      </div>
                  </div>
                      
                  <div class="row" style="margin: 20px 0">
                      <div class="col-md-4 col-sm-4">
                          <p>Picture</p>
                          <div class="fileinput text-center {{ image ? 'fileinput-exists' : 'fileinput-new' }}" data-provides="fileinput">
                              <div class="fileinput-new thumbnail">
                                  <img src="./assets/img/image_placeholder.jpg" alt="...">
                              </div>
                              <div class="fileinput-preview fileinput-exists thumbnail">
                                  <img *ngIf="image" src="{{ image }}" alt="Uploaded Image" />
                              </div>
                              <div>
                                  <span class="btn btn-rose btn-round btn-file">
                                      <span class="fileinput-new">Select image</span>
                                      <span class="fileinput-exists">Change</span>
                                      <input type="file" (change)="onFileChange($event)" accept="image/*" />
                                  </span>
                                  <a href="#pablo" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="remove()"><i class="fa fa-times"></i> Remove</a>
                              </div>
                          </div>
                      </div>
                      
                      <div class="col-md-8">
                          <div class="row">
                              <div class="col-md-12 col-sm-12">
                                  <mat-checkbox type="checkbox" formControlName="is_group" value="true">
                                      Sub Part is Single selection
                                  </mat-checkbox>
                              </div>
                          </div>
                          
                          <div class="row">
                              <div class="col-md-12 col-sm-12">
                                  <mat-checkbox type="checkbox" formControlName="allow_empty" value="true">
                                      Is Optional
                                  </mat-checkbox>
                              </div>
                          </div>
                          
                          <div class="row">
                              <div class="col-md-12 col-sm-12">
                                  <mat-checkbox type="checkbox" formControlName="allow_multi" value="true">
                                      Can be add repeatly
                                  </mat-checkbox>
                              </div>
                          </div>
                          
                          <div class="row">
                              <div class="col-md-12 col-sm-12">
                                  <mat-checkbox type="checkbox" formControlName="allow_add" value="true">
                                      Can be add by app
                                  </mat-checkbox>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Updating' : 'Update') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  