import { ErrorHandler, Injectable, Inject } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  shownError;
  
  constructor() {
  }

  handleError(error) {
	console.error(error);
	if(!this.shownError) {
		this.shownError = true;
		
		this.showError(error);
	}
  }
  
  showError(error) {
	var cThis = this;
	
	Swal.fire({      
		title: "Opps Something Wrongs", 
		text: "There is some error(s) happened that may affect visual, report to admin for fix.", 
		icon: "warning",
		showCloseButton: true,
		footer: error.message
	}).then(() => {
		cThis.shownError = false;
	});
  }
}