<div style="background: #fff; background-size: cover; background-position: top center;min-height: 100vh">
    <div class="container">
      <div class="row">
        <div class="col-10 col-sm-8 ml-auto mr-auto" style="margin-top: 40px">
			<form #f="ngForm" (ngSubmit)="onSubmit(f)" autocomplete="off">
              <img src="./assets/img/logo.png" class="login-logo">
              <div class="row">
                <div class="col-sm-12">
                  <h3 class="login-title">{{ translations['sign_in'] || 'Sign In' }}</h3>
                </div>
                <div class="col-sm-12">
                  <p class="login-label">{{ translations['email'] || 'Email' }}</p>
                  <input class="input" [class.is-invalid]="errors['email']" type="email" name="email" [placeholder]="translations['email_address'] || 'Email Address'" ngModel />
				  
				  <div class="invalid-feedback" role="alert" *ngIf="errors['email']">
					{{ errors['email'] }}
				  </div>
                </div>

                <div class="col-sm-12" style="position: relative;">
                  <p class="login-label">{{ translations['password'] || 'Password' }}</p>
                  <input class="input" [class.is-invalid]="errors['password']" [type]="hide ? 'password' : 'text'" name="password" [placeholder]="translations['password'] || 'Password'" ngModel />
				  <i class="password-btn" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
					<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
				  </i>
							
				  <div class="invalid-feedback" role="alert" *ngIf="errors['password']">
					{{ errors['password'] }}
				  </div>
                </div>
                <div class="col-sm-4 ml-auto mr-auto">
                <button class="btn-signin">{{ submitting ? (translations['signing_in'] || 'Signing In') 
					: (translations['sign_in'] || 'Sign In') }}</button>
                </div>

                <div class="col-sm-12">
                  <p class="login-forgot" (click)="forgot()">{{ translations['forgot_password'] || 'Forgot Password?' }}</p>
                </div>
              </div>
			</form>
        </div>
      </div>
	  <p style="text-align: center; margin-bottom: 0">App Version <span style="color: #e32526 !important">{{ version }}</span></p>
    </div>
</div>