import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss']
})
export class LightboxComponent implements OnChanges {

  @Output() download = new EventEmitter;
  @Output() cancel = new EventEmitter;
  
  @Input() images = [];
  @Input() index;  
  main : any = {};

  constructor(
	private sanitizer: DomSanitizer) { }

  ngOnChanges() {
	  this.updateMain();
  }
  
  updateMain() {
	  if(this.images) {
		if(this.index >= 0 && this.index < this.images.length) {		
			this.main = this.images[this.index];
		} else if(this.images.length > 0) {		
			this.main = this.images[0];
		}
	  }	  
  }
  
  gallery(src) {
	if(src) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(src);
	} else {
		return 'assets/img/defaultPhoto.gif';
	}
  }
  
  left() {
	  if(this.haveLeft()) {
		this.index--;
		this.updateMain();
	  }	  
  }
  
  right() {
	  if(this.haveRight()) {
		this.index++;
		this.updateMain();
	  }
  }
  
  haveLeft() {
	  return this.index > 0;
  }
  
  haveRight() {
	  return this.index < this.images.length - 1;
  }
  
  select(index) {
	  if(index >= 0 && index < this.images.length) {
		this.index = index;
		this.updateMain();
	  }
  }
}
