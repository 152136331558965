<div class="main-content" style="margin-top: 0px">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <h3 style="margin-bottom: 30px">Product Measurement List</h3>
      </div>
      <div class="col-lg-4 ml-auto" >
        <a class="btn btn-fill btn-rose" style="float: right;" (click)="add()">Add New</a>
      </div>
          <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">assignment</i>
                  </div>
                    <h4 class="card-title">Product Measurement List</h4>
                </div>
                  <div class="card-body">
                      <div class="toolbar">
                         <div class="form-group col-md-4 ml-auto">
                          <input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." >
                        </div>
                      </div>
                      <div class="material-datatables">
                        <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                              <thead>
                                  <tr>
                  <th></th>
                                    <th>Name</th>
                                    <th>Symbol</th>
                                    <th>Product using it</th>
                                    <th>Action</th>
                                  </tr>
                              </thead>
                              <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                                  <tr *ngIf="!measurementList">
                  <td colspan="7" class="text-center">Loading measurement...</td>
                </tr>
                <tr *ngIf="measurementList && measurementList.length == 0">
                  <td colspan="7" class="text-center">No available measurement.</td>
                </tr>
                <tr *ngIf="term && filteredList.length == 0">
                  <td colspan="7" class="text-center">No matched measurement.</td>
                </tr>
                                  <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn" cdkDrag cdkDragLockAxis="y">
                                      <th class="col-xs">
                    <div class="drag-handle" cdkDragHandle>
                      <i class="material-icons">swap_vert</i>
                    </div>
                  </th>
                  <td>{{ item.name }}</td>
                                      <td>{{ item.short }}</td>
                                      <td>
                    <label *ngIf="item.specifications == 0">No any product using this measurement.</label>
                    <div *ngFor="let specification of item.specifications" class="row">
                      <div class="col-12">{{ specification.product_part ? (specification.product_part.product ? specification.product_part.product.name : specification.product_part.name) : "De-activated" }}</div>
                    </div>
                  </td>
                                      <td>
                                        <a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
                                        <a class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
          <div class="pull-right" style="margin-top: 15px">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
