import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-sales-commission',
  templateUrl: './sales-commission.component.html',
  styleUrls: ['./sales-commission.component.css']
})
export class SalesCommissionComponent implements OnInit, OnDestroy {

  filteredList = [];
  commissionList;
  from_date;
  to_date;
  term;
  p;
  
  translations = {};
  id;
  name;
  
  subscriber;
  userList = [];
  user_id = "";
  settingList = {};
  
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.USER_LISTING), { role: "Salesman" }
	).then((userList : any) => {
		this.userList = userList;
	}, err => {
		//
	});
	
	this.startObs();
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getSalesCommissionsObs().subscribe(commissionList => {
		this.commissionList = commissionList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	this.subscriber.unsubscribe();
  }
  
  haveFilter() {
	  return this.term || this.user_id || this.to_date || this.from_date;
  }

  filter() {
	  if(!this.commissionList) {
		  return;
	  }
	  
	  if(!this.haveFilter()) {
		  this.filteredList = this.commissionList;
		  return;
	  }
	  
	  var term = this.term?.toLowerCase();
	  
	  var result = [];
	  
	  this.commissionList.forEach(item => {
		  if(this.user_id && item.user_id != this.user_id) {
			return;
		  }
		  
		  const createdDate = new Date(item.created_at);
		  if(this.from_date) {
		    const fromDate = new Date(this.from_date);
			if(createdDate < fromDate) {
			  return;
			}
		  }
		  if(this.to_date) {
		    const toDate = new Date(this.to_date);
			if(createdDate > toDate) {
			  return;
			}
		  }
		  
		  if(term) {
			  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
				result.push(item);
				return;
			  }
			  
			  if(item.amount && item.amount >= parseFloat(term)) {
				result.push(item);
				return;
			  }
			  
			  if(item.status && item.status.toLowerCase().indexOf(term) > -1 
				|| "pending".indexOf(term) > -1) {
				result.push(item);
				return;
			  }
			  
			  if(item.created_at && new Date(item.created_at).toString().indexOf(term) > -1) {
				result.push(item);
				return;
			  }
		  } else {
			result.push(item);
		  }
	  });
	  
	  this.filteredList = result;
  }
    
  view(index, item) {
    this.router.navigate(['sales_commission/show', { uid: item.id }]);
  }
  
  remove(index, item) {
	if(item.deleting) {
		return;
	}
	
	Swal.fire({
		title: "Delete Sales Commission",
		text: "Do you want to delete sales commission for " + item.qoutation?.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.USER_COMMISSION_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.commissionList.splice(index, 1);
				
				this.filter();
				item.deleting = false;
			}, err => {
				item.deleting = false;
				Swal.fire("Sales Commission Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  itemTrackBy(index, item) {
	return item.id;
  }
}
