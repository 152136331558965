<div style="background: #fff; background-size: cover; background-position: top center;min-height: 100vh">
	<div class="container">
	  <div class="row">
		<div class="col-10 col-sm-8 ml-auto mr-auto">
			<div class="row" style="min-height: 400px;">
			  <div class="col-12 text-center" style="margin: auto;">
				<!--<h5>The page you are looking for is moved or deleted, if you sure it is available, try to clear browser cache and <a [href]="route()" (click)="reload()">refresh</a>.</h5>-->
			  </div>
			</div>
		</div>
	  </div>
	</div>
</div>