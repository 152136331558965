<div class="main-content" style="margin-top: 0px">

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">Dashboard</h3>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-header card-header-success card-header-icon">
              <div class="card-icon">
                <i class="material-icons">today</i>
              </div>
              <p class="card-category">Ongoing Follow Up</p>
              <h3 class="card-title">{{ followUpList ? followUpList.length : "-" }}</h3>
            </div>
            <div class="card-footer">
              <div class="stats">
                <a [routerLink]="['/follow_up', { status: 'ongoing' }]"><i class="material-icons">local_offer</i> More Detail</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-header card-header-warning card-header-icon">
              <div class="card-icon">
                <i class="material-icons">help</i>
              </div>
              <p class="card-category">Pending Quotation</p>
              <h3 class="card-title">{{ quotationList ? pendingQuotation() : "-" }}</h3>
            </div>
            <div class="card-footer">
              <div class="stats">
                <a [routerLink]="['/quotation', { status: 'pending' }]"><i class="material-icons">local_offer</i> More Detail</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-header card-header-rose card-header-icon">
              <div class="card-icon">
                <i class="material-icons">paid</i>
              </div>
              <p class="card-category">Uncollected Deposit (RM)</p>
              <h3 class="card-title">{{ quotationList ? (pendingDeposit(quotationList) | number: "1.2-2") : "-" }}</h3>
            </div>
            <div class="card-footer">
              <div class="stats">
                <a [routerLink]="['/invoice', { status: 'pending' }]"><i class="material-icons">local_offer</i> More Detail</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="card card-stats">
            <div class="card-header card-header-info card-header-icon">
              <div class="card-icon">
                <i class="material-icons">paid</i>
              </div>
              <p class="card-category">Uncollected Payment (RM)</p>
              <h3 class="card-title">{{ quotationList ? (pendingPayment(quotationList) | number: "1.2-2") : "-" }}</h3>
            </div>
            <div class="card-footer">
              <div class="stats">
                <a [routerLink]="['/invoice', { status: 'not_paid' }]"><i class="material-icons">local_offer</i> More Detail</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="chartOptions[enlargedChartIndex]; let enlargedChart" class="row">
		<div class="col-md-12">
          <div class="card card-chart">
            <div [class]="enlargedChart.headerClass">
              <div id="enlargedChart" class="ct-chart"></div>
            </div>
            <div class="card-body">
              <h4 class="card-title">{{ enlargedChart.title }}</h4>
			  <div class="row">
				<div class="col-4">
				  <select class="form-control" [(ngModel)]="selectedDateOption" (ngModelChange)="changeDate()">
					<option *ngFor="let option of dateOptions" [value]="option.value">{{ option.text }}</option>
				  </select>
				</div>
				<div class="col-4">
				  <select class="form-control" [(ngModel)]="selectedRegionOption" (ngModelChange)="loadChart()">
					<option *ngFor="let option of regionOptions" [value]="option.value">{{ option.text }}</option>
				  </select>
				</div>
				<div class="col-4">
				  <select class="form-control" [(ngModel)]="selectedGroupOption" (ngModelChange)="loadChart()">
					<option *ngFor="let option of groupOptions" [value]="option.value">{{ option.text }}</option>
				  </select>
				</div>
			  </div>
			  <div *ngIf="selectedDateOption === 'CUSTOM'" class="row">
				<div class="col-6">
				  <input class="form-control" [ngModel]="startDateInput" type="date" (ngModelChange)="changeInputDate($event, null)" />
				</div>
				<div class="col-6">
				  <input class="form-control" [ngModel]="endDateInput" type="date" (ngModelChange)="changeInputDate(null, $event)" />
				</div>
			  </div>
            </div>
          </div>
        </div>
	  </div>
	  
      <div class="row">
         <div *ngFor="let option of chartOptions; let i = index" class="col-md-4">
          <div class="card card-chart">
            <div [class]="option.headerClass">
              <div [id]="option.id" class="ct-chart"></div>
            </div>
            <div class="card-body">
              <h4 class="card-title">{{ option.title }}</h4>
              <p class="card-category">Last 14 Days</p>
			  <i (click)="enlargeChart(option, i)" class="material-icons"
				style="float: right; position: absolute; right: 10px; top: 40%;">fullscreen</i>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
         <div class="col-md-6">
          <div class="card">
            <div class="card-header card-header-primary card-header-icon">
              <div class="card-icon">
                <i class="material-icons">assignment</i>
              </div>
              <h4 class="card-title" style="display: inline-block;">Sales Order Detail</h4>
                 <div style="display: inline-block; color: #000; float: right; margin-top: 10px; cursor: pointer;"(click)="moreSO()">More Detail
                </div>
            </div>
              <div class="card-body">
                  <div class="material-datatables">
                    <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                      <thead>
                          <tr>
                            <th>Identifier</th>
                            <th>Total Amount(RM)</th>
                            <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>                         
						<tr *ngIf="!salesOrderList">
							<td colspan="7" class="text-center">Loading sales order...</td>
						</tr>
						<tr *ngIf="salesOrderList && salesOrderList.length == 0">
							<td colspan="7" class="text-center">No available sales order.</td>
						</tr>
						<tr *ngFor="let item of getTop(salesOrderList, 3); let i = index; trackBy: trackByFn">
							<td>{{ item.name }}</td>
							<td>{{ item.quotation?.confirmed?.amount | number: '1.2-2' }}</td>
							<td>
								<a class="btn btn-link btn-success btn-just-icon view"><i class="material-icons" (click)="view('Sales Order', i, item)" title="Remove">launch</i></a>
								<a class="btn btn-link btn-danger btn-just-icon remove" [class.disabled]="item.deleting"><i class="material-icons" (click)="remove('Sales Order', i, item)" title="Remove">close</i></a>
							</td>
						</tr>
                      </tbody>
                  </table>
                  </div>
              </div>
          </div>
        </div>
         <div class="col-md-6">
          <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">assignment</i>
                    </div>
                      <h4 class="card-title" style="display: inline-block;">Quotation Detail</h4>
                       <div style="display: inline-block; color: #000; float: right; margin-top: 10px; cursor: pointer;" (click)="moreQT()">More Detail
                      </div>
                  </div>
                    <div class="card-body">
                        <div class="material-datatables">
                          <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
                                      <th>Identifier</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
									<ng-container *ngIf="filterPending(quotationList); let filtered">
										<tr *ngIf="!filtered">
											<td colspan="7" class="text-center">Loading quotation...</td>
										</tr>
										<tr *ngIf="filtered && filtered.length == 0">
											<td colspan="7" class="text-center">No available quotation.</td>
										</tr>
										<tr *ngFor="let item of getTop(filtered, 3); let i = index; trackBy: trackByFn">
											<td>{{ item.name }}</td>
											<td>
											  <select [ngModel]="item.status ? item.status : ''" (ngModelChange)="updateStatus(item, $event)" class="form-control">
												<option value="rejected">Rejected</option>
												<option value="">Pending</option>
												<option value="approved">Approved</option>
											  </select>
											</td>
											<td>
												<a class="btn btn-link btn-success btn-just-icon view"><i class="material-icons" (click)="view('Quotation', i, item)" title="Remove">launch</i></a>
												<a class="btn btn-link btn-danger btn-just-icon remove" [class.disabled]="item.deleting"><i class="material-icons" (click)="remove('Quotation', i, item)" title="Remove">close</i></a>
											</td>
										</tr>
									</ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
      </div>
    </div>
</div>  