import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	email: new FormControl('', [Validators.required, Validators.email]),
	code: new FormControl('', []),
	phone: new FormControl('', []),
	address: new FormControl('', []),
	area: new FormControl('', []),
	state: new FormControl('', []),
	password: new FormControl('', []),
	password_confirmation: new FormControl('', []),
	maximum_discount_percentage: new FormControl('', []),
	maximum_discount_amount: new FormControl('', []),
	regions: new FormGroup({}),
	id: new FormControl('', []),
	role: new FormControl('', []),
  });

  key;
  submitting;

  file;
  image;

  fileList = [];

  translations = {};
  hide = true;
  hideConfirmation = true;
  regionList = [];
  role;

  stateList = [
	"Selangor",
	"Kuala Lumpur",
	"Johor",
	"Perak",
	"Penang",
	"Negeri Sembilan",
	"Melaka",
	"Pahang",
	"Sabah",
	"Sarawak",
	"Kedah",
	"Putrajaya",
	"Kelantan",
	"Terengganu",
	"Perlis",
	"Labuan"
  ];
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('user');
	
	this.key = this.aroute.snapshot.params['uid'];	
	this.role = this.aroute.snapshot.params['role'];
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		var regionGroup = this.form.controls.regions as FormGroup;
		
		regionList.forEach(region => {
			regionGroup.addControl(region.id, new FormControl(""));
		});
		
		this.regionList = regionList;
		
		this.initFormData();
	}, err => {
		this.initFormData();
	});	
  }
  
  async initFormData() {
	if(this.key) {			
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.USER_GET, [this.key])
		).then((data : any) => {
			var regions = {};
			
			data.user_regions.forEach(uRegion => {
				regions[uRegion.region_id] = true;
			});
			
			this.form.patchValue({
				name: data.name,
				email: data.email,
				code: data.code,
				phone: data.phone,
				address: data.address,
				area: data.area,
				state: data.state,
				regions: regions,
				maximum_discount_amount: data.maximum_discount_amount,
				maximum_discount_percentage: data.maximum_discount_percentage,
			});
			
			this.image = data.image ? (APIService.STORAGE + data.image) : null;			
		}, err => {
			Swal.fire("Error on User Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
	  this.form.controls.password.setValidators([Validators.required]);
	  this.form.controls.password_confirmation.setValidators([Validators.required]);
	  this.form.controls.password.updateValueAndValidity();
	  this.form.controls.password_confirmation.updateValueAndValidity();
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		var data = this.form.value;
		
		if(this.key) {
			data.id = this.key;
		} else {
			data.role = this.role;
		}

		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.USER_UPDATE : APIService.USER_CREATE), data
		).then((res : any) => {			
			this.key = res.id;
			console.log(this.key)
			var fileProcessList = [];
			if(this.file) {
				var formData : FormData = new FormData();
				formData.append('image', this.file, this.file.name);
				formData.append('id', this.key);
				
				fileProcessList.push(
					this.apiService.post(
						this.apiService.getParameterizedURL(APIService.USER_UPLOAD), formData, {
							"Content-Type":false
						}
					).then((data : any) => {
						data.image = data;
					})
				);
			}
		
			merge(fileProcessList).toPromise().then(() => {				
				this.router.navigate(['user', { role: this.role }]).then(() => {
					this.submitting = false;
				});
			}, err => {
				this.submitting = false;
				if(err.status == 422) {
					let message = "";
					
					Object.keys(err.error.errors).forEach(key => {
						message += err.error.errors[key] + "\n";
					});
					
					Swal.fire("User Photo", message, 'warning');
				} else {
					Swal.fire("User Photo", this.apiService.handleStatusException(err), 'error');
				}
			});
		}, err => {
			this.submitting = false;
			if(err.status == 422) {
				Object.keys(err.error.errors).forEach(key => {
					this.form.get(key)?.setErrors({ custom: err.error.errors[key].join("\n") });
				});
				
				Swal.fire("User Submit", err.error.message, 'warning');
			} else {
				Swal.fire("User Submit", this.apiService.handleStatusException(err), 'error');
			}
		});
	}
  }

  selectAll(type, value) {
	if(!this.form.get(type)) {
		return;
	}

	var result = [];
	
	for(var i = 0; i < this.form.get(type).value.length; i++) {
		result[i] = value;
	}

	this.form.get(type).patchValue(result);
  }

  // dropzone
  onRemove(i) {
	this.fileList.splice(this.fileList.findIndex(i), 1);
  }

  onSelect(evt) {
	this.fileList = evt.addedFiles;
  }

  // profile
  onFileChange(evt) {
	this.file = evt.target.files.item(0);
  }

  remove() {
	this.image = null;
  }
}
