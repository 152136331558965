<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">Client</h3>
        </div>
        <div class="col-lg-4 ml-auto" >
          <a class="btn btn-fill btn-rose" style="float: right;" (click)="add()">Add New</a>
        </div>
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">assignment</i>
                    </div>
                      <h4 class="card-title">Client Detail</h4>
                  </div>
                    <div class="card-body">
                        <div class="toolbar">
                           <div class="form-group col-md-4 ml-auto">
                            <input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." >
                          </div>
                        </div>
                        <div class="material-datatables">
                          <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
									  <th></th>
                                      <th>Name</th>
                                      <th>Address</th>
                                      <th>Salesman</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
                                    <tr *ngIf="!clientList">
										<td colspan="7" class="text-center">Loading client...</td>
									</tr>
									<tr *ngIf="clientList && clientList.length == 0">
										<td colspan="7" class="text-center">No available client.</td>
									</tr>
									<tr *ngIf="term && filteredList.length == 0">
										<td colspan="7" class="text-center">No matched client.</td>
									</tr>
                                    <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn" cdkDrag cdkDragLockAxis="y">
                                        <th class="col-xs">
											<div class="drag-handle" cdkDragHandle>
												<i class="material-icons">swap_vert</i>
											</div>
										</th>
										<td>{{ item.company_name + (item.person ? (" - " +  item.person) : "") }}</td>
                                        <td style="white-space: pre-line;">{{ (item.address || "") + (item.area ? ("\n" +  item.area) : "") + (item.state ? ("\n" +  item.state) : "") + "\n\n" + item.phone }}</td>
                                        <td>
                                          <select [disabled]="item.assigning" [ngModel]="item.user_id ? item.user_id: ''" (ngModelChange)="updateSalesman(item, $event)" class="form-control">
                                            <option value="">No salesman selected</option>
                                            <option *ngFor="let user of userList | keyvalue" value="{{ user.key }}">{{ user.value }}</option>
                                          </select>
                                        </td>
										<td>
											<a class="btn btn-link btn-info btn-just-icon like"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a>
											<a class="btn btn-link btn-danger btn-just-icon remove"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
										
											<a class="btn btn-link btn-primary btn-just-icon more"><i class="material-icons" (click)="quotation(i, item)" title="Quotation(s)">menu</i></a>
                                        	<a class="btn btn-link btn-primary btn-just-icon more"><i class="material-icons" (click)="receipt(i, item)" title="Receipt(s)">menu</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>						
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
