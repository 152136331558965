import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements OnInit, OnDestroy {

  filteredList = [];
  receiptList;
  term;
  p;
  
  translations = {};
  id;
  name;
  
  subscriber;
  client_id;
  invoice_id
  ;
  clientList = [];
  
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.CLIENT_LISTING)
	).then((clientList : any) => {
		this.clientList = clientList;
	}, err => {
		//
	});
	
	this.aroute.params
	.subscribe(params => {
		this.client_id = params['c_uid'] || "";
		this.invoice_id = params['i_uid'] || "";
		
		this.stopObs();
	
		this.startObs();
	});
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getReceiptsObs({ c_id: this.client_id, i_id: this.invoice_id })
	.subscribe(receiptList => {
		this.receiptList = receiptList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }

  filter() {
	  if(!this.receiptList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.receiptList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.receiptList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.created_at && new Date(item.created_at).toString().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
	  });
	  
	  this.filteredList = result;
  }
  
  show(index, item) {
    this.router.navigate(['receipt/show', { uid: item.id }]);
  }

  add() {
    this.router.navigate(['receipt/add', { c_uid: this.client_id, i_uid: this.invoice_id }]);
  }
  
  edit(index, item) {
    this.router.navigate(['receipt/add', { uid: item.id }]);
  }

  remove(index, item) {
    if(item.deleting) {
		return;
	}
	
	Swal.fire({
		title: "Delete Receipt",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.RECEIPT_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.receiptList.splice(index, 1);
				
				this.filter();
				item.deleting = false;
			}, err => {
				item.deleting = false;
				Swal.fire("Receipt Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  trackByFn(index, item) {
	return item.id;
  }
  
  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			this.startObs();
			Swal.fire("Quotation Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
