<!--popup-->
<div class="popup-bg">
    <div class="popup-inner">
      <!--top button-->
      <div style="color: #fff; position: absolute; right: 10%;top: 5%">
          <i class="material-icons" (click)="download?.emit()">download</i>
          <i class="material-icons" (click)="cancel?.emit()">cancel</i>
      </div>
  
      <div class="row">
          <div class="col-1 text-center">
              <i *ngIf="haveLeft()" (click)="left()" class="material-icons">chevron_left</i>
          </div> 
          <div class="col-10">
              <div class="main-img">
                  <img class="d-block" [src]="gallery(main.src)" [alt]="main.name" [title]="main.name" width="100%" style="position: relative; object-fit: cover;" />
              </div>
          </div>
          <div class="col-1 text-center">
              <i *ngIf="haveRight()" (click)="right()" class="material-icons">chevron_right</i>
          </div>
      </div>
      <div class="row" style="max-width: 640px; display: flex; margin: auto">
          <div *ngFor="let image of images; let i = index" class="column-5">
              <div class=" sub-img">
                  <img class="d-block" [src]="gallery(image.src)" [alt]="image.name" [title]="image.name" width="100%" style="position: relative; object-fit: cover;" (click)="select(i)" />
              </div>
          </div>
      </div>
    </div>
  </div>