import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-receipt-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	cheque_no: new FormControl('', [Validators.required]),
	client_id: new FormControl('', [Validators.required]),
	rois: new FormArray([]),
	remark: new FormControl(''),
	receipt_date: new FormControl('', [Validators.required]),
	region_id: new FormControl(''),
	id: new FormControl('', []),
  });

  receipt;
  labelText : String;
  loading = false;
  client : any;
  clientList: [];
  key: string;
  submitting = false;
  
  title = "Receipt";  
  invoiceList : any[];
  cUID: string;
  iUID: string;
  
  regionList : [];

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService) {
  }

  ngOnInit(): void {
	this.key = this.aroute.snapshot.params['uid'];
	this.cUID = this.aroute.snapshot.params['c_uid'];
	this.iUID = this.aroute.snapshot.params['i_uid'];
	this.loading = true;
	this.labelText = "Loading Client...";
	
	this.initRegion();
	this.initFormData();
  }
  
  initRegion() {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
	).then((regionList : any) => {
		this.regionList = regionList;
	}, err => {
		Swal.fire(this.title + " Show", this.apiService.handleStatusException(err), 'error');
	});	
  }
  
  initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.RECEIPT_GET, [this.key])
		).then((receipt : any) => {
			this.receipt = receipt;
			this.client = receipt?.client;
			this.form.patchValue({
				name: receipt?.name,
				cheque_no: receipt?.cheque_no,
				client_id: receipt?.client_id,
				remark: receipt?.remark,
				receipt_date: this.datePipe.transform(receipt?.receipt_date, 'yyyy-MM-ddTHH:mm'),
			});
			this.loadInvoices();
			let receiptDatas = [];
			receipt?.receipt_on_invoices.forEach(roi => {
				receiptDatas.push({
					id: roi.invoice_id,
					client_id: roi?.client_id,
					name: roi.invoice?.name,
					amount: roi.paid_amount,
					remain_amount: roi.paid_amount
				});
			});
			this.generateInvoice(receiptDatas);
			this.loading = false;
		}, err => {
			Swal.fire(this.title + " Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
		// init date
		this.form.patchValue({
			receipt_date: this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm'),
		});
		// generate receipt ID
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.RECEIPT_ID), {}
		).then((res : any) => {
			this.form.patchValue({
				name: res.id
			});
		}, err => {
			Swal.fire("Receipt Auto ID", this.apiService.handleStatusException(err), 'error');
		});
		if(this.cUID) {
			// set client
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.CLIENT_GET, [this.cUID])
			).then((client : any) => {
				if (client.length > 0) {
					this.clientList = client;
					this.client = client[0];				
					this.form.patchValue({
						client_id: this.client.id,
					});
					this.invoiceList = this.client.invoices;
					this.loading = false;
				} else {
					this.labelText = "Client not found.";
				}
			}, err => {
				this.labelText = this.apiService.handleStatusException(err);
			});
		} else if(this.iUID) {
			// set invoice
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.CLIENT_LISTING), {
					invoice_id: this.iUID
				}
			).then((client : any) => {
				if (client.length > 0) {
					this.clientList = client;
					this.client = client[0];				
					this.form.patchValue({
						client_id: this.client.id,
					});
					this.invoiceList = this.client.invoices;
					const invoice = this.invoiceList.find(x => x.id + "" === this.iUID);
					if (invoice) {
						this.generateInvoice([{
							id: invoice.id, 
							name: invoice.name,
							remain_amount: (invoice.quotation.confirmed.amount - invoice.paid_amount)	
						}]);
						if (invoice.quotation?.confirmed) {
							this.form.patchValue({
								region_id: invoice.quotation.confirmed.region_id
							})
						}
					}
					this.loading = false;
				} else {
					this.labelText = "Client not found.";
				}
			}, err => {
				this.labelText = this.apiService.handleStatusException(err);
			});
		} else {
			// load all client
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.CLIENT_LISTING), {
					invoices: 1
				}
			).then((clientList : any) => {
				this.clientList = clientList;
				this.loading = false;
			}, err => {
				this.labelText = this.apiService.handleStatusException(err);
			});
		}
	}
  }
  
  clientChange() {
	this.loadInvoices();
  }
  
  loadInvoices() {
	this.ROIArray().clear();
	this.invoiceList = null;
	
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.INVOICE_LISTING), {
			c_uid: this.form.get('client_id').value
		}
	).then((invoiceList : any) => {
		this.invoiceList = invoiceList;
	}, err => {
		Swal.fire("Load Invoices", this.apiService.handleStatusException(err), 'error');
	});
  }
  
  generateInvoice(datas) {
	let ROIs = this.ROIArray();
	
	datas.forEach(data => {
		ROIs.push(new FormGroup({
			"invoice_id": new FormControl(data.id),
			"amount": new FormControl(data.amount, [Validators.required, Validators.max(data.remain_amount)])
		}));
	});
  }

  onFormSubmit() {
	var data = this.form.value;

	if(this.key) {
		data.id = this.key;
	}

	this.apiService.post(
		this.apiService.getParameterizedURL(this.key ? APIService.RECEIPT_UPDATE : APIService.RECEIPT_CREATE), data
	).then((res : any) => {
		this.router.navigate(['receipt']).then(() => {
			this.submitting = false;
		});
	} , err => {
		this.submitting = false;
		Swal.fire(this.title + (this.key ? " Update" : " Submit"), this.apiService.handleStatusException(err), 'error');
	});
  }
  
  addInvoice() {
	if(!this.invoiceList) {
		Swal.fire("Invoice Loading", "Invoice on selected client is still loading, please try later again.", "warning");
		return;
	}
	
	var options = {};
		
	this.invoiceList.filter(x => x.quotation?.confirmed).forEach(item => {
		options[item.id] = item.name + " (Remain RM " + (item.quotation.confirmed.amount - item.paid_amount).toFixed(2) + ")";
	});
	
	Swal.fire({
		title: "Add Invoice to Receipt",		
		input: 'select',
		inputOptions: options,
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.isConfirmed) {
			const invoice = this.invoiceList.find(x => x.id.toString() === result.value);
			this.generateInvoice([{
				id: result.value, 
				name: invoice.name,
				remain_amount: (invoice.quotation.confirmed.amount - invoice.paid_amount)
			}]);
		}
	});
  }
  
  getInvoiceData(id) {
	let invoice = this.invoiceList?.find(x => x.id == id);

	return invoice?.name;
  }
  
  removeInvoice(i) {
	this.ROIArray().removeAt(i);
  }
  
  ROIArray() {
	return this.form.get('rois') as FormArray;
  }
}
