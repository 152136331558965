<div class="main-content" style="margin-top: 0px">
	<div class="container">
		<div class="row">
			<div class="col-2">
				<img [src]="settingList['company_logo'] ? getSanitizerURL(settingList['company_logo']) : './assets/img/logo.png'" width="100%">
			</div>

			<div class="col-10" style="position: relative;">
				<h1 class="address-header" style=" position: absolute; top: 50%;  -ms-transform: translateY(-50%); transform: translateY(-50%);">{{ settingList['company_name'] || "BLone Enterprise Sdn Bhd" }} <span class="company-no">{{ settingList['company_reg_no'] || "648032-A" }}</span></h1>
			</div>
		</div>

		<div class="row">
			<div class="col-8" style="padding: 2.5px">
				<p style="font-weight: 700; color: #000; margin-bottom:0; padding-left: 20px">Receive from:</p>
				<div class="receipt-border">
					<p class="receipt-from">
						CASH SALES - {{ getCompanyName() }}
						<br />
						<span style="white-space: pre-line;">{{ receipt?.client?.address }}</span>
					</p>
				</div>
			</div>

			<div class="col-4" style="padding: 2.5px">
				<p style="font-weight: 700; color: #000; text-align: right; text-decoration: underline; margin-bottom: 0px; padding-right: 20px; font-size: 20px; margin-top: -7px">Official Receipt</p>
				<div class="receipt-border">
					<p style="margin-bottom: 20px; font-weight: 700">O/R No: <span style="font-size: 24px"> {{ receipt?.name }}</span></p>
					<p style="margin-bottom: 0">Date: <span> {{ receipt?.receipt_date | date: 'dd/MM/yyyy' }}</span></p>
					<!-- <p style="margin-bottom: 0">Page: <span> 1 of 1</span></p> -->
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12" style="padding: 2.5px">
				<div class="receipt-border" style="height: auto">
					<div class="row">
						<div class="col-1">
							<p class="particular">Particular:</p>
						</div>
						<div class="col-11">
							<p style="white-space: pre-line;">{{ receipt?.remark }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row" style="margin-top :10px">
			<div class="col-12" style="padding: 2.5px">
				<div class="row">
					<div class="col-1">
						<p class="sum-of">Sum of:</p>
					</div>
					<div class="col-11">
						<p class="total">{{ receipt?.total_paid_amount_words }}</p>
					</div>
				</div>
			</div>
		</div>

		<div class="row" style="margin-top :20px">
			<div class="col-12">
				<p class="invoice-no">Being Payment of Invoice(s) No:</p>
				<table class="table table-border table-padding">
					<tbody>
						<tr *ngFor="let group of groupedInvoices()">
							<td *ngFor="let colIndex of [0, 1, 2, 3, 4]" style="width: 20%; height: 60px;">
								<div *ngIf="group[colIndex]; let data" class="row">
									<div class="col-6">{{ data.invoice?.name }}</div>
									<div class="col-6">RM {{ data.paid_amount | number : '1.2-2' }}</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="row" style="margin-top :30px">
			<div class="col-9">
				<div class="row">
					<div class="col-5">
						<div class="receipt-border" style="height: auto">
							<p style="margin-bottom: 2.5px">Cheque No: {{ receipt?.cheque_no }}</p>
							<p style="margin-bottom: 2.5px">Amount: <span style="font-weight: 700"><u>RM {{ receipt?.total_paid_amount | number: '1.2-2' }}</u></span></p>
						</div>
					</div>
				</div>
				<p style="margin-top: 2.5px; font-size:14px; font-weight: 700">N.B : This receipt is only valkid subject to cheque honoured by the bank.</p>
			</div>
			<div class="col-3">
				<p style="font-weight: 700">Prepare By</p>
				<div class="receipt-chop">
					<img style="max-height: 50px;" *ngIf="settingList['company_chop']" [src]="getSanitizerURL(settingList['company_chop'])" />
				</div>
			</div>
		</div>

		<div *ngIf="receipt" class="row" style="margin-bottom: 40px">
			<div class="col-sm-10 ml-auto mr-auto">
				<div class="row">
					<div class="col-sm-3">
						<div class="btn btn-warning" (click)="pdf()">PDF</div>
					</div>								
					
					<div class="col-sm-3">
						<div class="btn btn-success" (click)="share()">Share</div>
					</div>
					
					<div class="col-sm-3">
						<div class="btn btn-info" (click)="edit()">Edit</div>
					</div>
					
					<div class="col-sm-3">
						<div class="btn btn-danger" (click)="remove()">{{ deleting ? 'Deleting...' : 'Delete' }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
