<div class="main-content" style="margin-top: 0px">
	<div class="container">
		<div class="row">
			<div *ngIf="commission" class="col-sm-12">
				<div class="row" style="margin: 0 -7px;">
					<div class="col-2">
						Salesman
					</div>
					<div class="col-2 text-right">
						<b>{{ commission.user?.name || '-' }}</b>
					</div>
					<div class="col-2">
						Quotation
					</div>
					<div class="col-2 text-right">
						<b>{{ commission.quotation?.name || '-' }}</b>
					</div>
					<div class="col-2">
						Sales Region
					</div>
					<div class="col-2 text-right">
						<b>{{ getVersion()?.region?.name || '-' }} ({{ getVersion()?.region?.commission_percentage || '-' }}%)</b>
					</div>
				</div>

				<div class="row">
					<div class="col-sm-12">
						<table class="table">
							<thead>
								<tr>
									<th class="text-left" width="1%">Item</th>
									<th class="text-left" width="20%">Description</th>
									<th class="text-right">Unit Price<br><span style="text-decoration: none!important">(RM)</span></th>
									<th class="text-right">QTY</th>
									<th class="text-right">Total Amount<br /><i>(Before Discount)</i><br><span style="text-align: center; text-decoration: none!important">(RM)</span></th>
									<th class="text-right">Total Amount<br /><i>(After Discount)</i><br><span style="text-align: center; text-decoration: none!important">(RM)</span></th>
									<th class="text-right">Costing Amount<br><span style="text-align: center; text-decoration: none!important">(RM)</span></th>
									<th class="text-right">Profit Amount<br><span style="text-align: center; text-decoration: none!important">(RM)</span></th>
									<th class="text-right">Commission Amount<br><span style="text-align: center; text-decoration: none!important">(RM)</span></th>
								</tr>
							</thead>								
							<ng-container *ngIf="getVersion(); let version">
								<tbody>
									<ng-container *ngFor="let item of version.items; let i = index">
										<tr style="text-decoration: underline;">
											<td>{{ getChar(i) }}</td>
											<td colspan="8" style="white-space: pre-line;">{{ item.name }}</td>
										</tr>
										<tr *ngFor="let part of item.item_parts; let j = index">
											<td>{{ j + 1 }}</td>
											<td style="white-space: pre-line;">{{ part.name }}</td>
											<td class="text-right">{{ parenthesesValue(part.amount) }}</td>
											<td class="text-right">{{ part.quantity + " " + part.measurement }}</td>
											<td class="text-right">{{ parenthesesValue(part.total) }}</td>
											<td class="text-right">{{ parenthesesValue(part.total + part.discounted_amount) }}</td>
											<td class="text-right">{{ parenthesesValue(part.costing_amount) }} ({{ part.costing_percentage || '-' }})%</td>
											<td class="text-right">{{ parenthesesValue(part.profit_amount) }}</td>
											<td class="text-right"><b>{{ parenthesesValue(part.commission_amount) }}</b></td>
										</tr>
									</ng-container>
									
									<ng-container *ngFor="let item of commission.extras; let i = index">
										<tr style="text-decoration: underline;">
											<td><i class="material-icons" (click)="removeExtra(item)" title="Remove" style="cursor: pointer;">close</i></td>
											<td colspan="7" style="white-space: pre-line;">{{ item.name }}</td>
											<td class="text-right">{{ parenthesesValue(-item.amount) }}</td>
										</tr>
									</ng-container>
								</tbody>
								<tfoot>
									<tr style="border-top: 1px solid black;">
										<td colspan="2">
											<button class="btn btn-primary" (click)="addExtra()">Add Extra Charge</button>
										</td>
										<td class="text-right" colspan="6">Total Commission</td>
										<td class="text-right"><b>{{ parenthesesValue(commission.amount) }}</b></td>
									</tr>
									<tr style="border-top: 1px solid black;">
										<td colspan="2">
											<button class="btn btn-warning" (click)="pdf()">PDF</button>
										</td>
										<td colspan="2">
											<button class="btn btn-success" (click)="share()">Share</button>
										</td>
										<td class="text-right" colspan="4">Uncollected Payment</td>
										<td class="text-right text-danger">
											{{ parenthesesValue(commission.quotation?.confirmed?.amount) }}
										</td>
									</tr>
									<tr>
										<td class="text-right" colspan="8">Uncollected Deposit</td>
										<td class="text-right text-danger">
											<b>{{ parenthesesValue(
												(commission.quotation?.confirmed?.amount
													* (commission.quotation?.confirmed?.deposit_percentage || 30) / 100)
												- commission.quotation?.paid_amount) }}</b>
										</td>
									</tr>
								</tfoot>
							</ng-container>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
