import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {

  translations = {};
  key;
  
  commission;
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('invoice');
	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }

  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.USER_COMMISSION_GET, [this.key])
		).then((commission : any) => {
			this.commission = commission;
		}, err => {
			Swal.fire("Sales Commission Viewing", this.apiService.handleStatusException(err), 'error');
		});
	}
  }
  
  async addExtra() {
	if(this.commission) {
		const queue = Swal.mixin({
		  progressSteps: ['1', '2'],
		});
		const result1 = await queue.fire({
			//currentProgressStep: "0",
			title: "Extra Commission Description",
			input: 'textarea',
			inputValidator: (val) => {
				if(!val) {
					return "Please describe the detail of extra commission.";
				}
			},
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: 'No, cancel it!',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonColor: "#e64942",
			focusCancel: true,
			reverseButtons: true,
		});
		
		if(!result1.isConfirmed) {
			return;
		}
		
		const result2 = await queue.fire({
			//currentProgressStep: "1",
			title: "Extra Commission Amount",
			input: 'text',
			inputValue: "",
			inputValidator: (val) => {
				if(!val) {
					return "Please input amount for extra commission.";
				}
				if(isNaN(parseFloat(val))) {
					return "Amount must be digit.";
				}
			},
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: 'No, cancel it!',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonColor: "#e64942",
			focusCancel: true,
			reverseButtons: true,
		});
		
		if(!result2.isConfirmed) {
			return;
		}
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.USER_COMMISSION_EXTRA), {
				commission_id: this.key,
				name: result1.value,
				amount: result2.value
			}
		).then((commission : any) => {
			this.commission = commission;
		}, err => {
			Swal.fire("Sales Commission Extra", this.apiService.handleStatusException(err), 'error');
		});
	}
  }
  
  removeExtra(item) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.USER_COMMISSION_EXTRA_DELETE), {
			commission_id: this.key,
			extra_id: item.id,
		}
	).then((commission : any) => {
		this.commission = commission;
	}, err => {
		Swal.fire("Sales Commission Extra", this.apiService.handleStatusException(err), 'error');
	});
  }
 
  getVersion() {
	  return this.commission?.quotation?.confirmed;
  }
  
  getChar(i: number) {
	  return String.fromCharCode(i + 65);
  }
  
  parenthesesValue(value) {
	  const stringValue = value >= 0 ? value.toFixed(2) : ("(" + Math.abs(value).toFixed(2) + ")");
	  return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  
  pdf() {
	  if(this.commission) {
		window.open(APIService.USER_COMMISSION_PDF + "?id=" + this.commission.id, "_blank");
	  }
  }
  
  share() {
	  if(this.commission) {
		window.open(APIService.USER_COMMISSION_SHARE + "?id=" + this.commission.id, "_blank");
	  }
  }
}
