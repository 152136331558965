<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">Sales Commission</h3>
        </div>
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">paid</i>
                    </div>
                      <h4 class="card-title">Sales Commission</h4>
                  </div>
                    <div class="card-body">
                        <div class="toolbar">
							<div class="row form-group">
								<div class="col-sm-8">
									<div class="row form-group">
										<div class="col-sm-6">
											<input type="datetime-local" class="form-control" [(ngModel)]="from_date" (ngModelChange)="filter()" />
										</div>
										<div class="col-sm-6">
											<input type="datetime-local" class="form-control" [(ngModel)]="to_date" (ngModelChange)="filter()" />
										</div>
									</div>
									<div class="row form-group">
										<div class="col-sm-6">
											<select class="form-control" [(ngModel)]="user_id" (ngModelChange)="filter()">
												<option value="">All salesman</option>
												<option *ngFor="let item of userList" [value]="item.id">{{ item.name }}</option>
											</select>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." >
								</div>
							</div>
                        </div>
                        <div class="material-datatables">
                          <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
                                      <th>Quotation Identifier</th>
                                      <th>Salesman</th>
                                      <th>Amount</th>
                                      <th>Created Date</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="!commissionList">
										<td colspan="7" class="text-center">Loading sales commission...</td>
									</tr>
									<tr *ngIf="commissionList && commissionList.length == 0">
										<td colspan="7" class="text-center">No available sales commission.</td>
									</tr>
									<tr *ngIf="haveFilter() && filteredList.length == 0">
										<td colspan="7" class="text-center">No matched sales commission.</td>
									</tr>
                                    <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: itemTrackBy">
                                        <td>{{ item.quotation?.name }}</td>
										<td>{{ item.user?.name }}</td>
                                        <td>{{ item.amount | number: '1.2-2' }}</td>
                                        <td class="text-center">{{ item.created_at | date: 'short' }}</td>
                                        <td>
										  <a class="btn btn-link btn-success btn-just-icon view"><i class="material-icons" (click)="view(i, item)" title="View Detail(s)">launch</i></a>
                                          <a class="btn btn-link btn-danger btn-just-icon remove" [class.disabled]="item.deleting"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
