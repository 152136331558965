import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../shared/api.service';
import { LocalizeService } from './../../shared/localize.service';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  key;

  translations = {};
  
  quotation;
  settingList = {};
  
  current_version_id;
  
  generating;
  processing;
  deleting;
  
  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
	private localizeService: LocalizeService,
	private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
	this.translations = this.localizeService.getTranslation('quotation');
	
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  initSetting(regionId) {
	this.apiService.post(
		this.apiService.getParameterizedURL(APIService.SETTING_GET_MULTI), {
			keys: [
				'company_name',
				'company_email',
				'company_website',
				'company_address',
				'company_fax',
				'company_sst',
				'company_logo',
				'company_reg_no',
				'company_phone',
				'company_chop',
				'company_chop_text',
				'note',
				'deposit_percentage',
			],
			region_id: regionId
		}
	).then((settingList : any) => {
		this.settingList = settingList;
	}, err => {
		this.apiService.handleStatusException(err);
	});
  }

  initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_GET, [this.key])
		).then((quotation : any) => {
			this.quotation = quotation;
			if(this.quotation?.confirmed_id) {
				this.current_version_id = this.quotation?.confirmed_id;
			} else if(this.quotation.primary_id) {
				this.current_version_id = this.quotation?.primary_id;
			} else if(this.quotation.versions.length > 0) {
				this.current_version_id = this.quotation?.versions[this.quotation?.versions.length - 1].id;
			} else {
				this.current_version_id = "";
			}
			this.initSetting(this.getVersion()?.region_id);
		}, err => {
			Swal.fire("Quotation Viewing", this.apiService.handleStatusException(err), 'error');
		});
	} else {
		Swal.fire("Quotation Viewing", "Invalid Link.", 'error');
	}
  }
  
  changeQuotation(value) {
	this.current_version_id = value;
	
	this.initSetting(this.getVersion()?.region_id);
  }
  
  getVersion() {
	return this.quotation?.versions.find(x => x.id == this.current_version_id);
  }
  
  getSanitizerURL(path) {
	  return this.sanitizer.bypassSecurityTrustResourceUrl(APIService.STORAGE + path);
  }
  
  getSanitizerHTML(html) {
	  return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  
  getLines(text) {
	  return text.split("\n");
  }
  
  pdf() {
	  if(this.quotation) {
		window.open(APIService.QUOTATION_PDF + "?id=" + this.quotation.id + "&v_id=" + this.current_version_id, "_blank");
	  }
  }
  
  share() {
	  if(this.quotation) {
		window.open(APIService.QUOTATION_SHARE + "?id=" + this.quotation.id + "&v_id=" + this.current_version_id, "_blank");
	  }
  }
  
  generate() {
	if(!this.generating) {
		const generateDoc = Swal.mixin({
			title: "Generate Document",
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
  			confirmButtonText: "Next",
  			cancelButtonText: "No, cancel it!",
			cancelButtonColor: "#e64942",
			focusCancel: true,
  			reverseButtons: true,
		})
		generateDoc.fire({
  			text: "Document Type",
			input: "select",
			inputOptions: {
				"0": 'Select Document Type',
				"1": 'Sales Order',
				"2": 'Invoice',
			},
			inputValidator: (value) => {
				if (value == "0") {
				  return 'Please select a Document Type.'
				}
			},
		}).then((result) => {
			if(result.isConfirmed){
				var docType = result.value;
				generateDoc.fire({
					input: 'textarea',
					text: "Remark",
					confirmButtonText: 'Yes, I am sure!',
				}).then((result)=>{
					if(result.isConfirmed){
						switch(docType){
							case "1":
								this.generating = true;
								this.apiService.post(
									this.apiService.getParameterizedURL(APIService.SALES_ORDER_CREATE), {
										quotation_id: this.quotation.id,
										version_id: this.current_version_id,
										remark: result.value[1],
									}
								).then((res : any) => {
									Swal.fire("Sales Order Generation", "Sales Order is generated.", 'success');
									this.generating = false;
								}, err => {
									this.generating = false;
									Swal.fire("Sales Order Generation", this.apiService.handleStatusException(err, true), 'error');
								});
								break;
							case "2":
								this.generating = true;
								this.apiService.post(
									this.apiService.getParameterizedURL(APIService.INVOICE_CREATE), {
										quotation_id: this.quotation.id,
										version_id: this.current_version_id,
										remark: result.value[1],
									}
								).then((res : any) => {
									Swal.fire("Invoice Generation", "Invoice is generated.", 'success');
									this.generating = false;
								}, err => {
									this.generating = false;
									Swal.fire("Invoice Generation", this.apiService.handleStatusException(err, true), 'error');
								});
								break;
						}
					}
				})
			}
		});
		
	}
  }
	
  followup() {
	this.router.navigate(['follow_up/show', { q_uid: this.quotation.id }]);
  }
  
  getDocumentField(key, field) {
	const ele = document.getElementById(key) as any;
	
	return ele[field];
  }
  
  editDeposit(index) {
	if(this.processing) {
		return;
	}
	
	if (!this.quotation.payment_receives[index]) {
		if (index === 0) {
			this.quotation.payment_receives[index] = { percentage: parseInt(this.settingList['deposit_percentage']) || 60, day: 0 };
		} else {
			this.quotation.payment_receives[index] = { percentage: 0, day: 0 };
		}
	}
	
	let html = "<div class='row'>";
	
	if (index === 0) {
		html += "<div class='col-12'>"
			+ "Confirmation Date</div>"
			+ "<div class='col-12'>"
			+ "<input class='form-control' id='date' type='date' value='"
			+ this.datePipe.transform(this.quotation.collection_date || this.quotation.created_at, 'yyyy-MM-dd') + "' /></div>";
	} else {
		html += "<div class='col-12'>"
			+ "Day(s) after confirmation</div>"
			+ "<div class='col-12'>"
			+ "<input class='form-control' id='day' type='number' value='"
			+ this.quotation.payment_receives[index].day + "' /></div>";
	}
	html += "<div class='col-12'>"
		+ "Deposit Rate</div>"
		+ "<div class='col-12'>"
		+ "<input class='form-control' id='percentage' type='number' value='"
		+ (this.quotation.payment_receives[index].percentage?.toFixed(2) || "0.00") + "'  /></div>";
	
	html += "</div>";
	
	Swal.fire({
		title: 'Edit Deposit',
		html: html,
		preConfirm: () => {
			return index === 0 ? [
			  this.getDocumentField('date', 'value'),
			  this.getDocumentField('percentage', 'value')
			] : [
			  this.getDocumentField('day', 'value'),
			  this.getDocumentField('percentage', 'value')
			]
		},
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.isConfirmed) {
			this.processing = true;
			this.quotation.payment_receives[index].day = index === 0 ? 0 : parseFloat(result.value[0]);
			this.quotation.payment_receives[index].percentage = parseFloat(result.value[1]);
			const date = index === 0 ? result.value[0] : null;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.QUOTATION_UPDATE), {
					id: this.quotation.id,
					payment_receives: this.quotation.payment_receives,
					collection_date: date
				}
			).then((res : any) => {
				this.quotation.payment_receives = res;
				if (date) {
					this.quotation.collection_date = date;
				}
				this.processing = false;
			}, err => {
				this.processing = false;
				Swal.fire("Quotation Deposit", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }
  
  addDeposit() {
	if (this.quotation.payment_receives?.length === 0) {
		this.quotation.payment_receives = [{ percentage: this.settingList['deposit_percentage'] || 60, day: 0 }];
	}
	this.quotation.payment_receives.push({ percentage: 0, day: 0 });
  }
  
  removeDeposit(index) {
	if(this.processing) {
		return;
	}
	
	this.processing = true;
	const removedItem = this.quotation.payment_receives.splice(index, 1);
	if(removedItem[0].id) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_UPDATE), {
				id: this.quotation.id,
				payment_receives: this.quotation.payment_receives
			}
		).then((res : any) => {
			this.quotation.payment_receives = res;
			this.processing = false;
		}, err => {
			this.processing = false;
			Swal.fire("Quotation Deposit", this.apiService.handleStatusException(err, true), 'error');
		});
	} else {
		this.processing = false;
	}
  }
  
  deleteVersion() {
	  if(!this.deleting) {
		this.deleting = true;
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.QUOTATION_VERSION_DELETE), {
				id: this.quotation.id,
				version_id: this.current_version_id,
			}
		).then((res : any) => {
			let currentVersionIndex = this.quotation.versions.findIndex(x => x.id == this.current_version_id);
			let currentVersion = this.quotation.versions[currentVersionIndex];
			
			Swal.fire("Quotation Version", "Version " + currentVersion.version + " successfully deleted.", "success");
		
			this.quotation.versions.splice(currentVersionIndex, 1);
			
			this.changeQuotation(this.quotation.versions[this.quotation.versions.length - 1]?.id || "");
			this.deleting = false;
		}, err => {
			this.deleting = false;
			throw this.apiService.handleStatusException(err);		
		});
	}
  }
  
  getDeposit(index, defaultPerc, field) {
	  if (this.quotation?.payment_receives?.length > index) {
		return this.quotation.payment_receives[index][field];
	  } else {
		return defaultPerc;
	  }
  }
  
  getDepositBalance(defaultBalance) {
	  return 100 - (this.quotation?.payment_receives?.reduce((pv, obj) => pv + obj.percentage, 0) || defaultBalance);
  }
  
  additionalDeposits() {
	  if (this.quotation?.payment_receives?.length > 1) {
		return this.quotation.payment_receives.slice(1, this.quotation.payment_receives.length).map((d, i) => i + 1);
	  } else {
		return [];
	  }
  }
}
