<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
			  <h3 style="margin-bottom: 30px">Galleries in {{ prefix || "/" }}</h3>
			</div>
			
			<div class="col-lg-12"  style="margin-bottom: 30px">
				<ngx-dropzone (change)="onSelect($event)">
					<ngx-dropzone-label>Upload Image To Current Folder!</ngx-dropzone-label>
					<ngx-dropzone-preview *ngFor="let f of files">
						<ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
					</ngx-dropzone-preview>
				</ngx-dropzone>
			</div>
			
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-primary card-header-icon">
						<div class="card-icon">
							<i class="material-icons">assignment</i>
						</div>
						<h4 class="card-title">Galleries</h4>
					</div>
					<div class="card-body">
						<div class="toolbar">
							<div class="form-group col-md-4 ml-auto">
								<input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." />
							</div>
						</div>
						<div class="material-datatables">
							<div class="row">
								<div class="col-12 text-center" *ngIf="!folderList && !fileList">
									<label>Loading galleries...</label>
								</div>
								<div class="col-12 text-center" *ngIf="fileList && folderList && fileList.length == 0 && folderList.length == 0">
									<label>No available galleries.</label>
								</div>
								<div class="col-12 text-center" *ngIf="haveFilter() && fileList && folderList && filteredFileList.length == 0 && filteredFolderList.length == 0">
									<label>No matched galleries.</label>
								</div>
								<div *ngFor="let path of filteredFolderList; let i = index; trackBy: trackByFn" class="col-sm-4">
									<div (click)="viewFolder(path)" style="cursor: pointer;">
										<i class="material-icons" [title]="path">folder</i>
										<label>{{ path }}</label>
									</div>
								</div>
								
								<div *ngFor="let file of filteredFileList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn" class="col-sm-4">
									<img (click)="viewImage(file, i)" [src]="imageDatas[file]?.src || loadingImg" (load)="loadImage(file)" [alt]="file" width="100%" style="margin-bottom: 30px; cursor: pointer;" />
									<label>{{ file }}</label>
								</div>
							</div>
						</div>
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</div>

<app-lightbox *ngIf="lightbox" 
	(download)="download()" (cancel)="cancel()" 
	[images]="getLightboxImages()" [index]="lightboxIndex"></app-lightbox>