<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
              <div class="card">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">{{ icon || 'mail_outline' }}</i>
                  </div>
                  <h4 class="card-title">{{ key ? pageEditName : pageAddName }}</h4>
                </div>
                <div class="card-body">
                  <div *ngFor="let row of formControls" class="row" style="margin: 0 20px 0 0">
                      <div *ngFor="let col of row" [class]="col.class_name || 'col-sm-12'" [class.v-center]="col.type == 'label'">
                          <ng-container [ngSwitch]="col.type">
                              <ng-container *ngSwitchCase="''">
                              </ng-container>
                              <ng-container *ngSwitchCase="'hidden'">
                                  <input type="hidden" [formControlName]="col.field" />
                              </ng-container>
                              <mat-label *ngSwitchCase="'label'">{{ col.text }}</mat-label>
                              <ng-container *ngSwitchCase="'select'">
                                  <mat-form-field *ngIf="form.get(col.field); let control" class="example-full-width">
                                      <mat-select [formControlName]="col.field">
                                          <mat-option value="">{{ col.placeholder }}</mat-option>
                                          <mat-option *ngFor="let data of col.datas" [value]="data[col.optionPairs?.value || 'id']">{{ data[col.optionPairs?.text || 'name'] }}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="control.hasError('required')">{{ col.requiredText || capitalize(col.field + ' is required.') }}</mat-error>
                                  </mat-form-field>
                              </ng-container>
                              <ng-container *ngSwitchCase="'textarea'">
                                  <mat-form-field *ngIf="form.get(col.field); let control" class="example-full-width">
                                    <mat-label>{{col.placeholder}}</mat-label>
                                      <textarea  matInput [rows]="col.row || '3'" [formControlName]="col.field"></textarea>
                                      <mat-error *ngIf="control.hasError('required')">{{ col.requiredText || capitalize(col.field + ' is required.') }}</mat-error>
                                  </mat-form-field>
                              </ng-container>
                              <ng-container *ngSwitchCase="'image_list'">
                                  <h5>{{ capitalize(col.field) }}</h5>
                                  <div *ngIf="!col.data">Loading {{ col.field }}...</div>
                                  <div *ngIf="col.data && col.data.length === 0">No {{ col.field }}.</div>
                                  <div *ngFor="let item of col.data | paginate: { itemsPerPage: col.size || 6, currentPage: col.page }; let i = index; trackBy: col.trackBy" [class]="col.image_class_name || 'col-sm-4'">
                                      <img [src]="item?.src || loadingImg" width="100%" style="margin-bottom: 30px; cursor: pointer;" />
                                      <label *ngIf="col.labelField">{{ item[col.labelField] }}</label>
                                  </div>
                              </ng-container>
                              <ng-container *ngSwitchCase="'date'">
                                  <mat-form-field *ngIf="form.get(col.field); let control" class="example-full-width">
                                    <mat-label>{{col.placeholder}}</mat-label>
                                      <input  matInput [matDatepicker]="picker" standalone="true"  maxlength="255" [formControlName]="col.field" />
                                      <mat-hint>MM/DD/YYYY</mat-hint>
                                      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                      <mat-datepicker #picker></mat-datepicker>
                                      <mat-error *ngIf="control.hasError('required')">{{ col.requiredText || capitalize(col.field + ' is required.') }}</mat-error>
                                      <mat-error *ngIf="control.hasError('min')">{{ col.minText || capitalize(col.field + ' cannot less than ' +
                                          control.errors.min.min + (col.type != 'number' ? ' characters' : '') + '.') }}</mat-error>
                                      <mat-error *ngIf="control.hasError('max')">{{ col.maxText || capitalize(col.field + ' cannot more than ' +
                                          control.errors.max.max + (col.type != 'number' ? ' characters' : '') + '.') }}</mat-error>
                                  </mat-form-field>
                              </ng-container>
                              <ng-container *ngSwitchDefault>
                                <mat-form-field *ngIf="form.get(col.field); let control" class="example-full-width">
                                  <input  matInput [placeholder]="col.placeholder" maxlength="255" [type]="col.type" [formControlName]="col.field" />
                                  <mat-error *ngIf="control.hasError('required')">{{ col.requiredText || capitalize(col.field + ' is required.') }}</mat-error>
                                  <mat-error *ngIf="control.hasError('min')">{{ col.minText || capitalize(col.field + ' cannot less than ' +
                                    control.errors.min.min + (col.type != 'number' ? ' characters' : '') + '.') }}</mat-error>
                                  <mat-error *ngIf="control.hasError('max')">{{ col.maxText || capitalize(col.field + ' cannot more than ' +
                                    control.errors.max.max + (col.type != 'number' ? ' characters' : '') + '.') }}</mat-error>
                                </mat-form-field>
                              </ng-container>
                          </ng-container>
                      </div>
                  </div>
                </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (submitting ? (editingText || 'Updating') : (editText || 'Update')) : 
                      (submitting ? (addingText || 'Submitting') : (addText || 'Submit')) }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  