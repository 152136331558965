<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ (key ? 'Edit ' : 'Input ') + role }}</h4>
                </div>
                <div class="card-body">
                    <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-8">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Name" maxlength="255" type="text" formControlName="name" />						
                              <mat-error *ngIf="form.controls.name.hasError('required')">Name is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Code" maxlength="255" type="text" formControlName="code" />
                          </mat-form-field>
                      </div>
                  </div>
                      
                  <div class="row" style="margin: 0 20px 0 0">
                       <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Email Address" maxlength="255" type="text" formControlName="email" />						
                              <mat-error *ngIf="form.controls.email.hasError('required')">Email Address is required.</mat-error>
                              <mat-error *ngIf="form.controls.email.hasError('email')">Please enter in email format something@domain.com.</mat-error>
                              <mat-error *ngIf="form.controls.email.errors?.custom; let custom">{{ custom }}</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Phone Number" maxlength="255" type="text" formControlName="phone" />						
                              <mat-error *ngIf="form.controls.phone.hasError('required')">Phone Number is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                  
                   <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-8">
                          <mat-form-field class="example-full-width">
                              <textarea matInput placeholder="Address" maxlength="8000" formControlName="address" rows="3"></textarea>						
                              <mat-error *ngIf="form.controls.address.hasError('required')">Address is required.</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <div class="row">
                              <div class="col-sm-12">
                                  <mat-form-field class="example-full-width">									
                                      <mat-select placeholder="State" formControlName="state">
                                          <mat-option value="" viewValue="Not specificed">Not specificed</mat-option>
                                          <mat-option value="{{state}}" viewValue="{{state}}" *ngFor="let state of stateList">{{ state }}</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="form.controls.state.hasError('required')">State is required.</mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="row">
                              <div class="col-sm-12">
                                  <mat-form-field class="example-full-width">
                                      <input matInput placeholder="Area" maxlength="255" type="text" formControlName="area" />						
                                      <mat-error *ngIf="form.controls.area.hasError('required')">Area is required.</mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                      </div>
                   </div>
                   
                  <div class="row" style="margin: 0 20px 20px 0">
                      <div class="col-sm-12">
                          <ng-container *ngFor="let region of regionList" formGroupName="regions">
                              <mat-checkbox [formControlName]="region.id">{{ region.name }}</mat-checkbox>
                              <br />
                          </ng-container>
                      </div>
                  </div>
                  
                  <div *ngIf="role == 'Salesman'" class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Maximum Discount Amount" type="number" formControlName="maximum_discount_amount" />
                          </mat-form-field>
                      </div>
                      <div class="col-sm-6">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Maximum Discount Percentage" min="0" max="100" type="number" formControlName="maximum_discount_percentage" />
                          </mat-form-field>
                      </div>
                  </div>
                    
                   <div class="row" style="margin: 20px 0">
                      <div class="col-md-4 col-sm-4">
                          <p>Picture</p>
                          <div class="fileinput text-center {{ image ? 'fileinput-exists' : 'fileinput-new' }}" data-provides="fileinput">
                              <div class="fileinput-new thumbnail">
                                  <img src="./assets/img/image_placeholder.jpg" alt="...">
                              </div>
                              <div class="fileinput-preview fileinput-exists thumbnail">
                                  <img *ngIf="image" src="{{ image }}" alt="Uploaded Image" />
                              </div>
                              <div>
                                  <span class="btn btn-rose btn-round btn-file">
                                      <span class="fileinput-new">Select image</span>
                                      <span class="fileinput-exists">Change</span>
                                      <input type="file" (change)="onFileChange($event)" accept="image/*" />
                                  </span>
                                  <a href="#pablo" class="btn btn-danger btn-round fileinput-exists" data-dismiss="fileinput" (click)="remove()"><i class="fa fa-times"></i> Remove</a>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                <div *ngIf="key" class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Updating' : 'Update') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
          
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="card">
                <div class="card-header card-header-rose card-header-icon">
                  <div class="card-icon">
                    <i class="material-icons">mail_outline</i>
                  </div>
                  <h4 class="card-title">{{ (key ? 'Edit ' : 'Input ') + role + " Password" }}</h4>
                </div>
                <div class="card-body">				
                   <div class="row" style="margin: 0 20px 0 0">
                      <div class="col-sm-8">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Password" maxlength="255" [type]="hide ? 'password' : 'text'" formControlName="password" />						
                              <i mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </i>
                              <mat-error *ngIf="form.controls.password.hasError('required')">Password is required.</mat-error>
                              <mat-error *ngIf="form.controls.password.errors?.custom; let custom">{{ custom }}</mat-error>
                          </mat-form-field>
                      </div>
                      
                      <div class="col-sm-4">
                          <mat-form-field class="example-full-width">
                              <input matInput placeholder="Password Confirmation" maxlength="255" [type]="hideConfirmation ? 'password' : 'text'" formControlName="password_confirmation" />						
                              <i mat-icon-button matSuffix (click)="hideConfirmation = !hideConfirmation" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <mat-icon>{{hideConfirmation ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </i>
                              <mat-error *ngIf="form.controls.password_confirmation.hasError('required')">Password Confirmation is required.</mat-error>
                          </mat-form-field>
                      </div>
                   </div>
                 </div>
                <div class="card-footer ml-auto" >
                  <button mat-raised-button class="btn btn-fill btn-rose" [disabled]="!(form.valid && !submitting)">{{ key ? 
                      (this.submitting ? 'Changing' : 'Change Password') : (this.submitting ? 'Submitting' : 'Submit') }}</button>
                </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  