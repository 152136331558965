import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, GuardsCheckStart, GuardsCheckEnd } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { APIService } from './shared/api.service';

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  version;
  latestVersion;
  updateRequired;
  loading;
	
  private _router: Subscription;
  
  private clientsObs;
  private gallerysObs;
  private followUpsObs;
  private invoicesObs;
  private productsObs;
  private partsObs;
  private quotationsObs;
  private receiptsObs;
  private salesOrdersObs;
  private salesRegionsObs;
  private salesCommissionsObs;
  private usersObs;
  
  // private clientData : any = {};
  private invoiceData : any = {};
  private galleryData : any = {};
  private followUpData : any = {};
  private productData : any = {};
  private partData : any = {};
  private quotationData : any = {};
  private receiptData : any = {};
  private salesOrderData : any = {};
  private salesRegionData : any = {};
  private salesCommissionData : any = {};
  private userData : any = {};
  
  constructor(
	private meta: Meta,
	private router: Router,
	private apiService: APIService) {
  }

	ngOnInit() {
	  this.initializeGuardLoader();
	  
      this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        const body = document.getElementsByTagName('body')[0];
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
        if (body.classList.contains('modal-open')) {
          body.classList.remove('modal-open');
          modalBackdrop.remove();
        }
      });
	  
	  this.checkVersion(latestVersion => {
		this.latestVersion = latestVersion;
	  });
    }

  initializeGuardLoader() {
	this.router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.loading = true;
      }     
      if (event instanceof GuardsCheckEnd) {
        this.loading = false;
      } 
    });
  }
	
	checkVersion(callback = null) {
		this.getVersion(version => {
			this.version = version;
		  
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.VERSION_GET)
			).then((latestVersion : any) => {
				if(latestVersion) {
					var appNums = version.split(".");
					var serverNums = latestVersion.split(".");
					 
					for(var i = 0; i < appNums.length; i++) {
						var appNum = appNums.length > i ? parseInt(appNums[i]) : 0;
						var serverNum = serverNums.length > i ? parseInt(serverNums[i]) : 0;
						 
						if(appNum < serverNum) {
							this.updateRequired = true;
							 // version is below, update is suggested
						}
					}
				}
			 
				if(callback) {
					callback(latestVersion);
				}
			}).catch(err => {
				this.apiService.handleStatusException(err);
			});
		});
	}

	getVersion(callback) {
	  var meta = this.meta.getTag('name=version');
	  callback(meta.content);
	}
	
	public getClientsObs() {
		if(this.clientsObs) {
			return this.clientsObs;
		}
		
		this.clientsObs = new Observable((observer) => {			
			this.getClientData(observer);
		});
		
		return this.clientsObs;
	}

	getClientData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.CLIENT_LISTING), {}
			, () => this.getClientData(observer));
	}
	
	public getGalleriesObs(galleryData = {}) {
		this.galleryData = galleryData;
		
		if(this.gallerysObs) {
			return this.gallerysObs;
		}
		
		this.gallerysObs = new Observable((observer) => {			
			this.getGalleryData(observer);
		});
		
		return this.gallerysObs;
	}

	getGalleryData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.GALLERY_LISTING), this.galleryData
			, () => this.getGalleryData(observer));
	}
	
	public getFollowUpsObs(followUpData = {}) {
		this.followUpData = followUpData;
		
		if(this.followUpsObs) {
			return this.followUpsObs;
		}
		
		this.followUpsObs = new Observable((observer) => {			
			this.getFollowUpData(observer);
		});
		
		return this.followUpsObs;
	}

	getFollowUpData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.FOLLOW_UP_LISTING), this.followUpData
			, () => this.getFollowUpData(observer));
	}

	public getInvoicesObs(invoiceData = {}) {
		this.invoiceData = invoiceData;
		
		if(this.invoicesObs) {
			return this.invoicesObs;
		}
		
		this.invoicesObs = new Observable((observer) => {			
			this.getInvoiceData(observer);
		});
		
		return this.invoicesObs;
	}

	getInvoiceData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.INVOICE_LISTING), this.invoiceData
			, () => this.getInvoiceData(observer));
	}
	
	public getPartsObs(partData = {}) {
		this.partData = partData;
		
		if(this.partsObs) {
			return this.partsObs;
		}
		
		this.partsObs = new Observable((observer) => {			
			this.getPartData(observer);
		});
		
		return this.partsObs;
	}

	getPartData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.PRODUCT_PART_LISTING), this.partData
			, () => this.getPartData(observer));
	}
	
	public getProductsObs(productData = {}) {
		this.productData = productData;
		
		if(this.productsObs) {
			return this.productsObs;
		}
		
		this.productsObs = new Observable((observer) => {			
			this.getProductData(observer);
		});
		
		return this.productsObs;
	}

	getProductData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.PRODUCT_LISTING), this.productData
			, () => this.getProductData(observer));
	}
	
	public getQuotationsObs(quotationData = {}) {
		this.quotationData = quotationData;
		
		if(this.quotationsObs) {
			return this.quotationsObs;
		}
		
		this.quotationsObs = new Observable((observer) => {			
			this.getQuotationData(observer);
		});
		
		return this.quotationsObs;
	}

	getQuotationData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.QUOTATION_LISTING), this.quotationData
			, () => this.getQuotationData(observer));
	}
	
	public getReceiptsObs(receiptData = {}) {
		this.receiptData = receiptData;
		
		if(this.receiptsObs) {
			return this.receiptsObs;
		}
		
		this.receiptsObs = new Observable((observer) => {			
			this.getReceiptData(observer);
		});
		
		return this.receiptsObs;
	}

	getReceiptData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.RECEIPT_LISTING), this.receiptData
			, () => this.getReceiptData(observer));
	}
	
	public getSalesCommissionsObs(salesCommissionData = {}) {
		this.salesCommissionData = salesCommissionData;
		
		if(this.salesCommissionsObs) {
			return this.salesCommissionsObs;
		}
		
		this.salesCommissionsObs = new Observable((observer) => {			
			this.getSalesCommissionData(observer);
		});
		
		return this.salesCommissionsObs;
	}

	getSalesCommissionData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.USER_COMMISSION_LISTING), this.salesCommissionData
			, () => this.getSalesCommissionData(observer));
	}
	
	public getSalesOrdersObs(salesOrderData = {}) {
		this.salesOrderData = salesOrderData;
		
		if(this.salesOrdersObs) {
			return this.salesOrdersObs;
		}
		
		this.salesOrdersObs = new Observable((observer) => {			
			this.getSalesOrderData(observer);
		});
		
		return this.salesOrdersObs;
	}

	getSalesOrderData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.SALES_ORDER_LISTING), this.salesOrderData
			, () => this.getSalesOrderData(observer));
	}
	
	public getSalesRegionsObs(salesRegionData = {}) {
		this.salesRegionData = salesRegionData;
		
		if(this.salesRegionsObs) {
			return this.salesRegionsObs;
		}
		
		this.salesRegionsObs = new Observable((observer) => {			
			this.getSalesRegionData(observer);
		});
		
		return this.salesRegionsObs;
	}

	getSalesRegionData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING), this.salesRegionData
			, () => this.getSalesRegionData(observer));
	}
	
	public getUsersObs(userData = {}) {
		this.userData = userData;
		
		if(this.usersObs) {
			return this.usersObs;
		}
		
		this.usersObs = new Observable((observer) => {			
			this.getUserData(observer);
		});
		
		return this.usersObs;
	}

	getUserData(observer) {
		this.handleObservation(observer, this.apiService.getParameterizedURL(APIService.USER_LISTING), this.userData
			, () => this.getUserData(observer));
	}
	
	handleObservation(observer, url, data, next, interval = 10000) {
		if(!observer.closed) {
			this.apiService.post(url, data).then((res : any) => {
				observer.next(res);
				setTimeout(next, interval);
			}).catch(err => {
				observer.error(err);		
				setTimeout(next, 5000);
			});
		}
	}
}
