import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APIService } from './../../../shared/api.service';
import Swal from 'sweetalert2';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { merge } from 'rxjs';

@Component({
  selector: 'app-cw-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  form = new FormGroup({
	name: new FormControl('', [Validators.required]),
	short: new FormControl('', []),
	id: new FormControl('', []),
  });

  key;
  submitting;

  translations = {};
  
  constructor(  
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService) {
  }

  ngOnInit(): void {
	this.key = this.aroute.snapshot.params['uid'];
	
	this.initFormData();
  }
  
  async initFormData() {
	if(this.key) {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.PRODUCT_MEASUREMENT_GET, [this.key])
		).then((data : any) => {
			this.form.patchValue({
				name: data.name,
				short: data.short,
			});	
		}, err => {
			Swal.fire("Error on Product Measurement Show", this.apiService.handleStatusException(err), 'error');
		});
	} else {
	}
  }

  onSubmit() {
    if(this.submitting) {
		return;
	}

	if(this.form.valid) {
		this.submitting = true;
		var data = this.form.value;

		if(this.key) {
			data.id = this.key;
		}

		this.apiService.post(
			this.apiService.getParameterizedURL(this.key ? APIService.PRODUCT_MEASUREMENT_UPDATE : APIService.PRODUCT_MEASUREMENT_CREATE), data
		).then((res : any) => {
			this.router.navigate(['product/measurement']).then(() => {
				this.submitting = false;
			});
		} , err => {
			this.submitting = false;
			Swal.fire("Error on Product Measurement Submit", this.apiService.handleStatusException(err), 'error');
		});
	}
  }
}
