<div class="main-content" style="margin-top: 0px">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <h3 style="margin-bottom: 30px">Invoice</h3>
        </div>
            <div class="col-md-12">
                <div class="card">
                  <div class="card-header card-header-primary card-header-icon">
                    <div class="card-icon">
                      <i class="material-icons">assignment</i>
                    </div>
                      <h4 class="card-title">Invoice Detail</h4>
                  </div>
                    <div class="card-body">
                        <div class="toolbar">
                          <div class="row form-group">
							<div class="col-sm-4">
								<mat-form-field appearance="fill">
								  <mat-label>Invoice Status</mat-label>
								  <mat-select [(ngModel)]="status" (ngModelChange)="filter()" multiple>
									<mat-option value="Quotation is Missing">Quotation is Missing</mat-option>
									<mat-option value="Quotation is Pending">Quotation is Pending</mat-option>
									<mat-option value="Deposit Pending">Deposit Pending</mat-option>
									<mat-option value="Deposit Received">Deposit Received</mat-option>
									<mat-option value="Paid">Paid</mat-option>
								  </mat-select>
								</mat-form-field>
							</div>
							<div class="offset-sm-4 col-md-4">
								<input name="no" type="text" class="form-control" [(ngModel)]="term" (ngModelChange)="filter()" placeholder="Search Keyword..." />
							</div>
                          </div>
                        </div>
                        <div class="material-datatables">
                          <table id="datatables" class="table table-striped table-no-bordered table-hover" cellspacing="0" width="100%" style="width:100%">
                                <thead>
                                    <tr>
									  <th></th>
                                      <th>Invoice No.</th>
                                      <th>Total Amount</th>
                                      <th>Paid Amount</th>
                                      <th>Remain Amount</th>
                                      <th>Date</th>
                                      <th>Status</th>
                                      <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
									<tr *ngIf="!invoiceList">
										<td colspan="7" class="text-center">Loading invoice...</td>
									</tr>
									<tr *ngIf="invoiceList && invoiceList.length == 0 && !haveFilter()">
										<td colspan="7" class="text-center">No available invoice.</td>
									</tr>
									<tr *ngIf="haveFilter() && filteredList.length == 0">
										<td colspan="7" class="text-center">No matched invoice.</td>
									</tr>
                                    <tr *ngFor="let item of filteredList | paginate: { itemsPerPage: 6, currentPage: p }; let i = index; trackBy: trackByFn" cdkDrag cdkDragLockAxis="y">
                                        <th class="col-xs">
											<div class="drag-handle" cdkDragHandle>
												<i class="material-icons">swap_vert</i>
											</div>
										</th>
										<td>{{ item.name }}</td>
                                        <td>{{ (item.quotation?.confirmed?.amount | currency: "RM ") || "Quotation is Pending" }}</td>
										<td>{{ item.paid_amount | currency: "RM " }}</td>
										<td>{{ (item.paid_amount - item.quotation?.confirmed?.amount || 0) | currency: "RM " }}</td>
										<td>{{ item.updated_at | date: 'short' }}</td>
										<td>{{ item.status }}</td>
                                        <td>
											<a class="btn btn-link btn-success btn-just-icon view"><i class="material-icons" (click)="view(i, item)" title="View Details">launch</i></a>
											<!-- <a class="btn btn-link btn-info btn-just-icon edit"><i class="material-icons" (click)="edit(i, item)" title="Edit">edit</i></a> -->
											<a class="btn btn-link btn-danger btn-just-icon remove" [class.disabled]="item.deleting"><i class="material-icons" (click)="remove(i, item)" title="Remove">close</i></a>
                                            <a class="btn btn-link btn-primary btn-just-icon more"><i class="material-icons" (click)="more(i, item)" title="View Receipts">menu</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
						<div class="pull-right" style="margin-top: 15px">
							<pagination-controls (pageChange)="p = $event"></pagination-controls>
						</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
