import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Chartist from 'chartist';
import { AppComponent } from './../app.component';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit, OnDestroy, AfterViewInit {

    clientList;
	quotationList;
	salesOrderList;
	followUpList = [];
	cSubscriber;
	qSubscriber;
	soSubscriber;
	settingList = {};
	
	chartOptions = [
     { 
		id: "barChart",
		type: "bar",
		labelMap: (datas) => datas?.map(x => x.name + " (" + x.user_id + ")"),
		seriesMap: (datas) => datas?.map(data => data.total),
		headerClass: "card-header card-header-info",
		title: "Salesman Commission", 
        options: {
          seriesBarDistance: 10,
          high: null
        },
        extraOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5
          }]
        ]
	  },
	  {
		id: "lineChart",
		type: "line",
		labelMap: (datas) => [],
		seriesMap: (datas) => [],
		headerClass: "card-header card-header-rose",
		title: "Quotation Sales",
		options: {
            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0
            }),
            low: 0,
            high: null,
            chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
            classNames: {
                point: 'ct-point ct-white',
                line: 'ct-line ct-white'
            }
        }
	  },
	  {
		id: "pieChart",
		type: "pie",
		labelMap: (datas) => datas?.map(data => data.name),
		seriesMap: (datas) => datas?.map(data => data.total),
		headerClass: "card-header card-header-warning",
		title: "Best Sales Product",
		options: {
			donut: true,
			startAngle: 180,
			total: null
        }
      },
	];
	dateOptions = [
		{ value: "14D", text: "Last 14 Days" },
		{ value: "1M", text: "Past Month" },
		{ value: "3M", text: "Past Quarter" },
		{ value: "6M", text: "Past Semi-Annual" },
		{ value: "1Y", text: "Past Annual" },
		{ value: "5Y", text: "PAST 5 Years" },
		{ value: "CUSTOM", text: "Custom" }
	];
	selectedDateOption = this.dateOptions[0].value;
	groupOptions = [
		{ value: "day", text: "Group By Day" },
		{ value: "month", text: "Group By Month" },
		{ value: "year", text: "Group By Year" }
	];
	selectedGroupOption = this.groupOptions[0].value;
	regionOptions = [
		{ value: "", text: "All Region" },
	];
	selectedRegionOption = this.regionOptions[0].value;
	valueBetween = 14;
	enlargedChartIndex;
	
	dashboard;
	endDate;
	startDate;
	endDateInput;
	startDateInput;
	dateChangeTimeout;
	dateLabels = [];
	
	regionList = [];
	
	constructor(
		private router: Router,
		private appComponent: AppComponent,
		private apiService: APIService,
	) { }
    
	ngOnInit() {
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SALES_REGION_LISTING)
		).then((regionList : any) => {
			this.regionList = regionList;
			regionList.forEach(region => {
				this.regionOptions.push({ value: region.id, text: region.name });
				this.loadSetting(region.id);
			});
		}, err => {
			// show error
			this.apiService.handleStatusException(err, false);
		});
		
		this.cSubscriber = this.appComponent.getClientsObs().subscribe(clientList => {
			this.clientList = clientList;
		}, err => {
			// show error
			this.apiService.handleStatusException(err, false);
		});
		
		this.qSubscriber = this.appComponent.getQuotationsObs({
			only_confirmed: 1
		}).subscribe(quotationList => {
			this.quotationList = quotationList;
			
			let labels = [];
			let series = [
				[]
			];
			
			for(let quotation of quotationList) {
				labels.push(quotation.name);
				series[0].push(quotation.confirmed?.amount);
			}
		}, err => {
			// show error
			this.apiService.handleStatusException(err, false);
		});
		
		this.soSubscriber = this.appComponent.getSalesOrdersObs().subscribe(salesOrderList => {
			this.salesOrderList = salesOrderList;
		}, err => {
			// show error
			this.apiService.handleStatusException(err, false);
		});
    }
	
	ngAfterViewInit() {
		this.chartOptions.forEach(chartOption => {
			this.initChart(chartOption.id, chartOption.labelMap(null), chartOption.seriesMap(null), chartOption);
		});
		this.changeDate();
	}
	
	initializeEndDate() {
		const endDate = new Date();
		endDate.setDate(endDate.getDate() + 1);
		this.endDate = endDate;
	}
	
	initializeStartDate(dayDiff, monthDiff = 0, yearDiff = 0) {
		const startDate = new Date(this.endDate);
		startDate.setDate(startDate.getDate() - dayDiff);
		startDate.setMonth(startDate.getMonth() - monthDiff);
		startDate.setFullYear(startDate.getFullYear() - yearDiff);
		this.startDate = startDate;
	}
	
	changeDate() {
		if(this.selectedDateOption !== "CUSTOM") {
			this.initializeEndDate();
		}
		
		switch(this.selectedDateOption) {
			case "14D":
				this.initializeStartDate(15);
				this.selectedGroupOption = this.groupOptions[0].value;
				break;
			case "1M":
				this.initializeStartDate(1, 1);
				this.selectedGroupOption = this.groupOptions[0].value;
				break;
			case "3M":
				this.initializeStartDate(1, 3);
				this.selectedGroupOption = this.groupOptions[1].value;
				break;
			case "6M":
				this.initializeStartDate(1, 6);
				this.selectedGroupOption = this.groupOptions[1].value;
				break;
			case "1Y":
				this.initializeStartDate(1, 0, 1);
				this.selectedGroupOption = this.groupOptions[1].value;
				break;
			case "5Y":
				this.initializeStartDate(1, 0, 5);
				this.selectedGroupOption = this.groupOptions[2].value;
				break;
		}
		this.startDateInput = this.convertDateOnly(this.startDate);
		this.endDateInput = this.convertDateOnly(this.endDate);
		this.loadChart();
	}
	
	changeInputDate(start, end) {
		let startTimestamp, endTimestamp;
		if(start) {
			startTimestamp = Date.parse(start);
			if (isNaN(startTimestamp)) {
				return;
			}
		}
		if(end) {
			endTimestamp = Date.parse(end);
			if (isNaN(endTimestamp)) {
				return;
			}
		}
		clearInterval(this.dateChangeTimeout);
		if(startTimestamp) {
			this.startDate = new Date(startTimestamp);
		}
		if(endTimestamp) {
			this.endDate = new Date(endTimestamp);
		}
		this.dateChangeTimeout = setTimeout(() => this.loadChart(), 500);
	}
	
	loadChart() {
		this.initDateLabels();
		
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.DASHBOARD), {
				from: this.convertDateWithoutTime(this.startDate),
				to: this.convertDateWithoutTime(this.endDate),
				region_id: this.selectedRegionOption,
			}
		).then((dashboard : any) => {
			this.dashboard = dashboard;
			this.initDashboard();
			this.initEnlargedChart();
		}, err => {
			// show error
			this.apiService.handleStatusException(err, false);
		});
	}
	
	enlargeChart(chartOption, index) {
		this.enlargedChartIndex = index;
		if(document.getElementById("enlargedChart")?.children.length > 0) {
			document.getElementById("enlargedChart").children[0].remove();
		}
		setTimeout(() => {
			this.initEnlargedChart();
		}, 500);
	}
	
	initEnlargedChart() {
		if(this.enlargedChartIndex === undefined) {
			return;
		}
		const chartOption = this.chartOptions[this.enlargedChartIndex];
		const chartData = this.dashboard[this.enlargedChartIndex];
		
		this.initChart("enlargedChart", chartOption.labelMap(chartData)
		, [chartOption.seriesMap(chartData)], chartOption)
	}
	
	initDateLabels() {
		this.dateLabels = [];
		const currentDate = new Date(this.endDate);
		let dayBetween = 0;
		if (this.selectedGroupOption === this.groupOptions[0].value) {
			dayBetween = (this.endDate - this.startDate) / 3600 / 24 / 1000;
		} else if (this.selectedGroupOption === this.groupOptions[1].value) {
			dayBetween = this.endDate.getMonth() - this.startDate.getMonth()
				+ ((this.endDate.getFullYear() - this.startDate.getFullYear()) * 12);
		} else if (this.selectedGroupOption === this.groupOptions[2].value) {
			dayBetween = (this.endDate - this.startDate) / 3600 / 24 / 1000 / 365;
		}
		if(dayBetween === 0) {
			dayBetween = 1;
		}
		for(let i = 0; i < dayBetween; i++) {
			this.dateLabels.unshift(this.convertDateString(currentDate));
			if (this.selectedGroupOption === this.groupOptions[0].value) {
				currentDate.setDate(currentDate.getDate() - 1);
			} else if (this.selectedGroupOption === this.groupOptions[1].value) {
				currentDate.setMonth(currentDate.getMonth() - 1);
			} else if (this.selectedGroupOption === this.groupOptions[2].value) {
				currentDate.setFullYear(currentDate.getFullYear() - 1);
			}
		}
		console.log(this.dateLabels);	
		this.chartOptions[1].labelMap = (datas) => this.dateLabels;
	}
	
	loadSetting(regionId) {
		if(!this.settingList[regionId]) {
			this.settingList[regionId] = {};
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.SETTING_GET_MULTI), {
					keys: [
						'deposit_percentage',
					],
					region_id: regionId
				}
			).then((settingList : any) => {
				this.settingList[regionId] = settingList;
			}, err => {
				this.apiService.handleStatusException(err);
			});
		}
	}
	
	initDashboard() {
		this.chartOptions[0].options.high = this.dashboard[0]?.reduce((pv, obj) => pv < obj.total ? obj.total : pv, 0);
		this.chartOptions[2].options.total = this.dashboard[2]?.reduce((pv, obj) => pv + obj.total, 0);
		
		let dateSeries = [];
		
		this.chartOptions[1].labelMap([]).forEach((label, index) => {
			dateSeries[index] = this.dashboard[1].reduce((pv, obj) => pv
				+ (this.convertDateString(new Date(obj.date)) == label ? parseFloat(obj.total) : 0), 0);
		});
		
		this.chartOptions[1].options.high = dateSeries.reduce((pv, value) => pv < value ? value : pv, 0);
		this.chartOptions[1].seriesMap = (datas) => dateSeries;
		
		this.chartOptions.forEach((chartOption, index) => {
			this.initChart(chartOption.id, chartOption.labelMap(this.dashboard[index])
				, [chartOption.seriesMap(this.dashboard[index])]
				, chartOption);
		});
	}
	
	convertDateOnly(date) {
	  return date? (date.getFullYear()
		  + "-" + (date.getMonth() + 1).toString().padStart(2, "0")
		  + "-" + date.getDate().toString().padStart(2, "0")) : null;
    }
	
	convertDateWithoutTime(date) {
	  const newDate = new Date(date);
	  newDate.setHours(0);
	  newDate.setMinutes(0);
	  newDate.setSeconds(0);
	  return newDate;
    }
	
	convertDateString(date) {
		if(!date) {
			return;
		}
		if (this.selectedGroupOption === this.groupOptions[0].value) {
			return date.getDate().toString().padStart(2, "0");
		} else if (this.selectedGroupOption === this.groupOptions[1].value) {
			return date.getFullYear()
		  + "-" + (date.getMonth() + 1).toString().padStart(2, "0");
		} else if (this.selectedGroupOption === this.groupOptions[2].value) {
			return date.getFullYear();
		}
    }
	
	initChart(elementId, labels = [], series = [[]], chartOption) {
        let data = {
          labels: labels,
          series: series
        };

		try {
			switch(chartOption.type) {
				case "bar":
					return new Chartist.Bar('#' + elementId, data, chartOption.options, chartOption.extraOptions);
				case "line":
					return new Chartist.Line('#' + elementId, data, chartOption.options, chartOption.extraOptions);
				case "pie":
					return new Chartist.Pie('#' + elementId, data, chartOption.options, chartOption.extraOptions);
			}
		} catch {
			// ignore query selector error
		}
	}
	
	ngOnDestroy() {
		this.cSubscriber.unsubscribe();
		this.qSubscriber.unsubscribe();
		this.soSubscriber.unsubscribe();
	}

    moreQT(){
		this.router.navigate(['quotation']);
    }

    moreSO(){
		this.router.navigate(['sales_order']);
    }
	
	filterPending(array) {
		return array ? array.filter(x => !x.status) : array
	}
	
	getTop(array, limit = 3) {
		return array ? array.sort((a, b) => a.updated_at - b.updated_at).slice(0, limit) : array;
	}
	
	trackByFn(index, item) {
		return item.id;
	}
	
	pendingPayment(quotationList) {
		const amount = quotationList.reduce((pv, obj) => {
			const version = obj.versions.find(x => x.id == obj.confirmed_id);
			if (obj.confirmed_id && obj.status == 'approved' && version) {
				return pv + version.amount - obj.paid_amount;
			} else {
				return pv;
			}
		}, 0);
		return amount > 0 ? amount : 0;
	}
	
	pendingDeposit(quotationList) {
		const amount = quotationList.reduce((pv, obj) => {
			const version = obj.versions.find(x => x.id == obj.confirmed_id);
			if(version) {
				const depositPercentage = version.deposit_percentage || this.settingList[version.region_id]?.deposit_percentage;
				if (obj.confirmed_id && obj.status == 'approved' && version && depositPercentage) {
					return pv + (version.amount * depositPercentage / 100) - obj.paid_amount;
				} else {
					return pv;
				}
			} else {
				return 0;
			}
		}, 0);
		return amount > 0 ? amount : 0;
	}
	
	view(type, index, item) {
		if(type == "Sales Order") {
			this.router.navigate(['sales_order/show', { uid: item.id }]);
		} else {
			this.router.navigate(['quotation/show', { uid: item.id }]);
		}
	}
	
	remove(type, index, item) {
		if(item.deleting) {
			return;
		}
		
		Swal.fire({
			title: "Delete " + type,
			text: "Do you want to delete " + item.name + "?",
			icon: "warning",
			showCloseButton: true,
			showCancelButton: true,
			focusConfirm: false,
			cancelButtonText: 'No, cancel it!',
			confirmButtonText: 'Yes, I am sure!',
			cancelButtonColor: "#e64942",
			focusCancel: true,
			reverseButtons: true,
		}).then(result => {
			if(result.value) {
				item.deleting = true;
				
				this.apiService.post(
					this.apiService.getParameterizedURL(type == "Sales Order" ? APIService.SALES_ORDER_DELETE :  APIService.QUOTATION_DELETE), {
						id: item.id
					}
				).then((res : any) => {
					if(type == "Sales Order") {
						this.salesOrderList.splice(index, 1);
					} else {
						this.quotationList.splice(index, 1);
					}
					
					item.deleting = false;
				}, err => {
					item.deleting = false;
					Swal.fire(type + " Delete", this.apiService.handleStatusException(err, true), 'error');
				});
			}
		});
	}
	
	updateStatus(item, value) {	
		if(!item.updating) {
			item.updating = true;
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.QUOTATION_STATUS), {
					id: item.id,
					status: value,
				}
			).then((res : any) => {
				item.updating = false;
			}, err => {
				item.updating = false;
				Swal.fire("Error on Quotation Status Update", this.apiService.handleStatusException(err, true), 'error');
			});		
		}
	}
	
	pendingQuotation() {
		return this.quotationList
			.filter(q => q.confimed_id && q.status == '').length;
	}
}
