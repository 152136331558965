import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class APIService {

   static HOST = "https://flexiquotebe.ergrouptech.com";
   static API_ENDPOINT = (APIService.HOST + "/admin");

  //static HOST = environment.production ? "https://api.bleno.co" : "http://localhost:8000";
  //static API_ENDPOINT = environment.production ? (APIService.HOST + "/admin") : (APIService.HOST + "/api/admin");

  static STORAGE = APIService.HOST + "/storage/";
  static VERSION_GET = APIService.API_ENDPOINT + "/version";
  
  static AUTH = APIService.API_ENDPOINT;
  static LOGIN = APIService.AUTH + "/login";
  static LOGOUT = APIService.AUTH + "/logout";
  static FORGOT_PASSWORD = APIService.AUTH + "/forgot";
  static RESET_PASSWORD = APIService.AUTH + "/reset";
  
  static TRANSLATION = APIService.API_ENDPOINT + "/translation";
  static TRANSLATION_CHANGE = APIService.TRANSLATION + "/{0}";
  
  static LOCALIZED = APIService.API_ENDPOINT;
  static DASHBOARD = APIService.LOCALIZED;
  static PROFILE_PHOTO = APIService.LOCALIZED + "/upload";
  
  static CLIENT = APIService.LOCALIZED + "/client";
  static CLIENT_LISTING = APIService.CLIENT;
  static CLIENT_ASSIGN = APIService.CLIENT + "/assign";
  static CLIENT_CREATE = APIService.CLIENT + "/create";
  static CLIENT_UPDATE = APIService.CLIENT + "/update";
  static CLIENT_UPLOAD = APIService.CLIENT + "/upload";
  static CLIENT_DELETE = APIService.CLIENT + "/delete";
  static CLIENT_GET = APIService.CLIENT + "/{0}";
  static CLIENT_REORDER = APIService.CLIENT + "/reorder";
  
  static FOLLOW_UP = APIService.LOCALIZED + "/follow_up";
  static FOLLOW_UP_LISTING = APIService.FOLLOW_UP;
  static FOLLOW_UP_CREATE = APIService.FOLLOW_UP + "/create";
  static FOLLOW_UP_UPDATE = APIService.FOLLOW_UP + "/update";
  static FOLLOW_UP_DELETE = APIService.FOLLOW_UP + "/delete";
  static FOLLOW_UP_DOWNLOAD = APIService.FOLLOW_UP + "/download";
  static FOLLOW_UP_GET = APIService.FOLLOW_UP + "/{0}";
  
  static GALLERY = APIService.LOCALIZED + "/gallery";
  static GALLERY_LISTING = APIService.GALLERY;
  static GALLERY_UPLOAD = APIService.GALLERY + "/upload";
  static GALLERY_DELETE = APIService.GALLERY + "/delete";
  static GALLERY_DOWNLOAD = APIService.GALLERY + "/download";
  
  static INVOICE = APIService.LOCALIZED + "/invoice";
  static INVOICE_LISTING = APIService.INVOICE;
  static INVOICE_CREATE = APIService.INVOICE + "/create";
  static INVOICE_UPDATE = APIService.INVOICE + "/update";
  static INVOICE_DELETE = APIService.INVOICE + "/delete";
  static INVOICE_GET = APIService.INVOICE + "/{0}";
  static INVOICE_REORDER = APIService.INVOICE + "/reorder";
  static INVOICE_SHARE = APIService.INVOICE + "/share";
  static INVOICE_PDF = APIService.INVOICE + "/pdf";
  
  static PRODUCT = APIService.LOCALIZED + "/product";
  static PRODUCT_LISTING = APIService.PRODUCT;
  static PRODUCT_ACTIVATION = APIService.PRODUCT + "/activation";
  static PRODUCT_CREATE = APIService.PRODUCT + "/create";
  static PRODUCT_UPDATE = APIService.PRODUCT + "/update";
  static PRODUCT_UPLOAD = APIService.PRODUCT + "/upload";
  static PRODUCT_DELETE = APIService.PRODUCT + "/delete";
  static PRODUCT_GET = APIService.PRODUCT + "/{0}";
  static PRODUCT_GET_PART = APIService.PRODUCT + "/{0}/parts";
  static PRODUCT_TRANSFER = APIService.PRODUCT + "/transfer";
  static PRODUCT_COPY = APIService.PRODUCT + "/copy";
  static PRODUCT_PART_DISCOUNT_LISTING = APIService.PRODUCT + "/parts";
   
  static PRODUCT_CATEGORY = APIService.PRODUCT + "/category";
  static PRODUCT_CATEGORY_LISTING = APIService.PRODUCT_CATEGORY;
  static PRODUCT_CATEGORY_CREATE = APIService.PRODUCT_CATEGORY + "/create";
  static PRODUCT_CATEGORY_UPDATE = APIService.PRODUCT_CATEGORY + "/update";
  static PRODUCT_CATEGORY_UPLOAD = APIService.PRODUCT_CATEGORY + "/upload";
  static PRODUCT_CATEGORY_DELETE = APIService.PRODUCT_CATEGORY + "/delete";
  static PRODUCT_CATEGORY_GET = APIService.PRODUCT_CATEGORY + "/{0}";
  static PRODUCT_CATEGORY_REORDER = APIService.PRODUCT_CATEGORY + "/reorder";
  
  static PRODUCT_DISCOUNT = APIService.PRODUCT + "/discount";
  static PRODUCT_DISCOUNT_LISTING = APIService.PRODUCT_DISCOUNT;
  
  static PRODUCT_PART = APIService.PRODUCT + "/part";
  static PRODUCT_PART_LISTING = APIService.PRODUCT_PART;
  static PRODUCT_PART_ACTIVATION = APIService.PRODUCT_PART + "/activation";
  static PRODUCT_PART_CREATE = APIService.PRODUCT_PART + "/create";
  static PRODUCT_PART_UPDATE = APIService.PRODUCT_PART + "/update";
  static PRODUCT_PART_UPLOAD = APIService.PRODUCT_PART + "/upload";
  static PRODUCT_PART_DELETE = APIService.PRODUCT_PART + "/delete";
  static PRODUCT_PART_GET = APIService.PRODUCT_PART + "/{0}";
  static PRODUCT_PART_GET_PART = APIService.PRODUCT_PART + "/{0}/parts";
  static PRODUCT_PART_TRANSFER = APIService.PRODUCT_PART + "/transfer";
  static PRODUCT_PART_ASSIGNMENT = APIService.PRODUCT_PART + "/assign";
  static PRODUCT_PART_COSTING = APIService.PRODUCT_PART + "/costing";
  static PRODUCT_PART_COPY = APIService.PRODUCT_PART + "/copy";
  static PRODUCT_PART_REORDER = APIService.PRODUCT_PART + "/reorder";
    	
  static PRODUCT_SPECIFICATION = APIService.PRODUCT + "/specification";
  static PRODUCT_SPECIFICATION_REORDER = APIService.PRODUCT_SPECIFICATION + "/reorder";
  
  static PRODUCT_MEASUREMENT = APIService.PRODUCT + "/measurement";
  static PRODUCT_MEASUREMENT_LISTING = APIService.PRODUCT_MEASUREMENT;
  static PRODUCT_MEASUREMENT_CREATE = APIService.PRODUCT_MEASUREMENT + "/create";
  static PRODUCT_MEASUREMENT_UPDATE = APIService.PRODUCT_MEASUREMENT + "/update";
  static PRODUCT_MEASUREMENT_DELETE = APIService.PRODUCT_MEASUREMENT + "/delete";
  static PRODUCT_MEASUREMENT_GET = APIService.PRODUCT_MEASUREMENT + "/{0}";
  static PRODUCT_MEASUREMENT_REORDER = APIService.PRODUCT_MEASUREMENT + "/reorder";
  
  static QUOTATION = APIService.LOCALIZED + "/quotation";
  static QUOTATION_LISTING = APIService.QUOTATION;
  static QUOTATION_CREATE = APIService.QUOTATION + "/create";
  static QUOTATION_UPDATE = APIService.QUOTATION + "/update";
  static QUOTATION_DELETE = APIService.QUOTATION + "/delete";
  static QUOTATION_GET = APIService.QUOTATION + "/{0}";
  static QUOTATION_REORDER = APIService.QUOTATION + "/reorder";
  static QUOTATION_STATUS = APIService.QUOTATION + "/status";
  static QUOTATION_SHARE = APIService.QUOTATION + "/share";
  static QUOTATION_PDF = APIService.QUOTATION + "/pdf";
  static QUOTATION_VERSION_DELETE = APIService.QUOTATION + "/version/delete";
  static QUOTATION_PART_REORDER = APIService.QUOTATION + "/version/item/part/reorder";
  static QUOTATION_COMMISSSION = APIService.QUOTATION + "/commission";
  
  static RECEIPT = APIService.LOCALIZED + "/receipt";
  static RECEIPT_LISTING = APIService.RECEIPT;
  static RECEIPT_CREATE = APIService.RECEIPT + "/create";
  static RECEIPT_UPDATE = APIService.RECEIPT + "/update";
  static RECEIPT_DELETE = APIService.RECEIPT + "/delete";
  static RECEIPT_GET = APIService.RECEIPT + "/{0}";
  static RECEIPT_REORDER = APIService.RECEIPT + "/reorder";
  static RECEIPT_STATUS = APIService.RECEIPT + "/status";
  static RECEIPT_SHARE = APIService.RECEIPT + "/share";
  static RECEIPT_PDF = APIService.RECEIPT + "/pdf";
  static RECEIPT_ID = APIService.RECEIPT + "/id";
  
  static SALES_ORDER = APIService.LOCALIZED + "/sales_order";
  static SALES_ORDER_LISTING = APIService.SALES_ORDER;
  static SALES_ORDER_CREATE = APIService.SALES_ORDER + "/create";
  static SALES_ORDER_UPDATE = APIService.SALES_ORDER + "/update";
  static SALES_ORDER_DELETE = APIService.SALES_ORDER + "/delete";
  static SALES_ORDER_GET = APIService.SALES_ORDER + "/{0}";
  static SALES_ORDER_REORDER = APIService.SALES_ORDER + "/reorder";
  static SALES_ORDER_STATUS = APIService.SALES_ORDER + "/status";
  static SALES_ORDER_SHARE = APIService.SALES_ORDER + "/share";
  static SALES_ORDER_PDF = APIService.SALES_ORDER + "/pdf";
  
  static SALES_REGION = APIService.LOCALIZED + "/sales_region";
  static SALES_REGION_LISTING = APIService.SALES_REGION;
  static SALES_REGION_CREATE = APIService.SALES_REGION + "/create";
  static SALES_REGION_UPDATE = APIService.SALES_REGION + "/update";
  static SALES_REGION_DELETE = APIService.SALES_REGION + "/delete";
  static SALES_REGION_GET = APIService.SALES_REGION + "/{0}";
  
  static SETTING = APIService.LOCALIZED + "/setting";
  static SETTING_LISTING = APIService.SETTING;
  static SETTING_GET_MULTI = APIService.SETTING + "/get";
  static SETTING_UPDATE = APIService.SETTING + "/update";
  
  static STAFF = APIService.LOCALIZED + "/staff";
  static STAFF_LISTING = APIService.STAFF;
  static STAFF_ACTIVATION = APIService.STAFF + "/activation";
  static STAFF_CREATE = APIService.STAFF + "/create";
  static STAFF_UPDATE = APIService.STAFF + "/update";
  static STAFF_DELETE = APIService.STAFF + "/delete";
  static STAFF_GET = APIService.STAFF + "/{0}";
  static STAFF_REORDER = APIService.STAFF + "/reorder";
    
  static STAFF_ROLE = APIService.LOCALIZED + "/staff/role";
  static STAFF_ROLE_LISTING = APIService.STAFF_ROLE;

  static USER = APIService.LOCALIZED + "/user";
  static USER_LISTING = APIService.USER;
  static USER_ACTIVATION = APIService.USER + "/activation";
  static USER_CREATE = APIService.USER + "/create";
  static USER_UPDATE = APIService.USER + "/update";
  static USER_UPLOAD = APIService.USER + "/upload";
  static USER_DELETE = APIService.USER + "/delete";
  static USER_GET = APIService.USER + "/{0}";
  static USER_REORDER = APIService.USER + "/reorder";
  
  static CHANGE_PASSWORD = APIService.USER + "/change_password";
  
  static USER_COMMISSION = APIService.USER + "/commission";
  static USER_COMMISSION_LISTING = APIService.USER_COMMISSION;
  static USER_COMMISSION_DELETE = APIService.USER_COMMISSION + "/delete";
  static USER_COMMISSION_GET = APIService.USER_COMMISSION + "/{0}";
  static USER_COMMISSION_SHARE = APIService.USER_COMMISSION + "/share";
  static USER_COMMISSION_PDF = APIService.USER_COMMISSION + "/pdf";
  
  static USER_COMMISSION_EXTRA = APIService.USER_COMMISSION + "/extra";
  static USER_COMMISSION_EXTRA_DELETE = APIService.USER_COMMISSION_EXTRA + "/delete";
  
  constructor(
    private router: Router,
	private http: HttpClient,
	private authService: AuthService
  ) { }
  
  post(url, data : any = {}, header = {}) {
	  const token = this.authService.getAuthHeader();
	  
	  let originalHeader : any = {
		  'Accept': "application/json",
		  'Content-Type': 'application/json'
	  };
	  
	  if(token) {
		  originalHeader['Authorization'] = token;
	  }
	  
	  const headers = new HttpHeaders(Object.assign(originalHeader, header));
	  
	  const locale = localStorage.getItem('locale');
	  const delimiter = url.indexOf("?") > -1 ? '&' : '?';
	  const fullUrl = url + (locale ? delimiter + "locale=" + locale : "");
	  if (!data.region_id) {
		data.region_id = localStorage.getItem('region');
	  }	  
	  return this.http.post(fullUrl, data, {
		  headers: headers
	  }).toPromise();
  }
  
  blob(url, data : any = {}) {
	  const token = this.authService.getAuthHeader();
	  
	  let originalHeader : any = {
		  'Content-Type': 'application/json'
	  };
	  
	  if(token) {
		  originalHeader['Authorization'] = token;
	  }
	  
	  const headers = new HttpHeaders(originalHeader);
	  
	  const locale = localStorage.getItem('locale');
	  const delimiter = url.indexOf("?") > -1 ? '&' : '?';
	  const fullUrl = url + (locale ? delimiter + "locale=" + locale : "");
	  
	  return this.http.post(fullUrl, data, {
		  headers: headers,
		  responseType: 'blob'
	  }).toPromise();
  }
  
  getParameterizedURL(url, params = []) {	
	  for (const param in params) {
		url = url.replace("{" + param + "}", params[param])
	  }
	  
	  return url;
  }
  
  handleStatusException(err, handling = false) {
	  switch(err.status) {
		  case 401:
		    this.authService.setAuthHeader(null);
			this.router.navigate(['login']);
			
			const msg = "Your account is logged in at another device or server is reseted your auth state, please relog and continue.";
			
			if(!handling) {
				Swal.fire("Error on Authentication", msg, 'success');
			}
			
			return msg;
		  case 403:
			return "Invalid access, make sure you have the right to access.";
		  case 404:
			return "Unsupported features, make sure your app is up-to-date.";
		  case 420:
			return err.error.message;
		  case 422:
			return "Unhandled validation errors, make sure your input is correct & available.";
		  case 429:
			return "Too many attempts, try again later.";
		  case 500:
			return "Unavailable features right now, please try again later or contact our support.";
		  case 503:
			return "Server maintenance, our server is under maintenance, please try again later.";
		  case 504:
			return "Process timeout, please check your internet connection or try again later.";
		  default:
			return "Unknown Error Occur (" + err.status + "), please try again later or update your app.";
	  }
  }
}
