import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';
import { AppComponent } from './../app.component';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-salesorder',
  templateUrl: './salesorder.component.html',
  styleUrls: ['./salesorder.component.css']
})
export class SalesorderComponent implements OnInit, OnDestroy {

  filteredList = [];
  salesOrderList;
  term;
  p;
  
  translations = {};
  id;
  name;
  
  subscriber;
  params;
  
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
	private apiService: APIService,
    private appComponent: AppComponent) { }

  ngOnInit(): void {
	this.aroute.params
	.subscribe(params => {
		this.params = params;
		
		this.stopObs();
	
		this.startObs();
	});
  }
  
  ngOnDestroy() {
	this.stopObs();
  }
  
  startObs(): void {
	this.subscriber = this.appComponent.getSalesOrdersObs({ q_id: this.params['q_uid'] })
	.subscribe(salesOrderList => {
		this.salesOrderList = salesOrderList;
		
		this.filter();
	}, err => {
		// show error
		this.apiService.handleStatusException(err);
	});
  }
  
  stopObs() {
	if(this.subscriber) {
		this.subscriber.unsubscribe();
	}
  }

  filter() {
	  if(!this.salesOrderList) {
		  return;
	  }
	  
	  if(!this.term) {
		  this.filteredList = this.salesOrderList;
		  return;
	  }
	  
	  var term = this.term.toLowerCase();
	  
	  var result = [];
	  
	  this.salesOrderList.forEach(item => {
		  if(item.name && item.name.toLowerCase().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.amount && item.amount >= parseFloat(term)) {
			result.push(item);
			return;
		  }
		  
		  if(item.status && item.status.indexOf(term) > -1
			|| "pending".indexOf(term) > -1) {
			result.push(item);
			return;
		  }
		  
		  if(item.created_at && new Date(item.created_at).toString().indexOf(term) > -1) {
			result.push(item);
			return;
		  }
	  });
	  
	  this.filteredList = result;
  }

  add() {
    this.router.navigate(['sales_order/show']);
  }
  
  edit(index, item) {
    this.router.navigate(['sales_order/show', { uid: item.id }]);
  }
  
  view(index, item) {
    this.router.navigate(['sales_order/show', { uid: item.id }]);
  }
  
  remove(index, item) {
	if(item.deleting) {
		return;
	}
	
	Swal.fire({
		title: "Delete Sales Order",
		text: "Do you want to delete " + item.name + "?",
		icon: "warning",
		showCloseButton: true,
		showCancelButton: true,
		focusConfirm: false,
		cancelButtonText: 'No, cancel it!',
		confirmButtonText: 'Yes, I am sure!',
		cancelButtonColor: "#e64942",
		focusCancel: true,
		reverseButtons: true,
	}).then(result => {
		if(result.value) {
			item.deleting = true;
			
			this.apiService.post(
				this.apiService.getParameterizedURL(APIService.SALES_ORDER_DELETE), {
					id: item.id
				}
			).then((res : any) => {
				this.salesOrderList.splice(index, 1);
				
				this.filter();
				
				item.deleting = false;
			}, err => {
				item.deleting = false;
				Swal.fire("Error on Sales Order Delete", this.apiService.handleStatusException(err, true), 'error');
			});
		}
	});
  }

  updateStatus(item, value) {
	if(!item.updating) {
		item.updating = true;
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SALES_ORDER_STATUS), {
				id: item.id,
				status: value
			}
		).then((res : any) => {
			item.updating = false;
		}, err => {
			item.updating = false;
			Swal.fire("Error on Sales Order Status Update", this.apiService.handleStatusException(err, true), 'error');
		});		
	}
  }
  
  trackByFn(index, item) {
	return item.id;
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if(event.previousIndex != event.currentIndex) {
		this.stopObs();
		
		var target = this.filteredList[event.currentIndex];
		
		moveItemInArray(this.filteredList, event.previousIndex, event.currentIndex);
		
		// update server
		this.apiService.post(
			this.apiService.getParameterizedURL(APIService.SALES_ORDER_REORDER), {
				id: this.filteredList[event.currentIndex].id,
				target_id: target.id,
				is_add: event.currentIndex > event.previousIndex ? 1 : 0,
			}
		).then((res : any) => {
			this.startObs();
		}, err => {
			this.startObs();
			Swal.fire("Error on Sales Order Re-Ordering", this.apiService.handleStatusException(err, true), 'error');
		});
	}
  }
}
