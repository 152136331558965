<div class="row">
	<div class="col-sm-12 so-template" >
		<table class="table">
			<thead>
				<tr>
					<th width="2%"></th>
					<th width="1%">Item</th>
					<th width="39%">Description</th>
					<th class="text-right" width="15%">Unit Price<br><span style="text-decoration: none!important">(RM)</span></th>
					<th class="text-right" width="20%">QTY</th>
					<th class="text-right" width="20%" class="text-right">Total Amount<br><span style="text-align: center; text-decoration: none!important">(RM)</span></th>
				</tr>
			</thead>								
			<ng-container *ngIf="current_version">
				<tbody *ngFor="let item of current_version.items; let i = index"
					 cdkDropList (cdkDropListDropped)="onDrop($event, item.id)">
					<tr *ngIf="item.comment" style="margin-bottom: 10px;">
						<td colspan="2"></td>
						<td class="so-comment" colspan="4">{{ item.comment }}</td>
					</tr>
					<tr style="text-decoration: underline;">
						<td></td>
						<td>{{ getChar(i) }}</td>
						<td style="white-space: pre-line;">{{ item.name }}</td>
						<td colspan="3"></td>
					</tr>
					<ng-container *ngFor="let part of summarizeDiscount(item.item_parts); let j = index">
						<tr cdkDrag cdkDragLockAxis="y" [class.start_discount]="j > 0 && part.start_discount">
							<th>
								<div class="drag-handle" cdkDragHandle>
									<i class="material-icons">swap_vert</i>
								</div>
							</th>
							<td>{{ j + 1 }}</td>
							<td style="white-space: pre-line;">{{ part.name }}</td>
							<td class="text-right">{{ parenthesesValue(part.amount) }}</td>
							<td class="text-right">{{ part.quantity + " " + part.measurement }}</td>
							<td class="so-amount text-right">{{ parenthesesValue(part.total) }}</td>
						</tr>
						<!--
						<ng-container *ngIf="part.discount_summary && part.discount_summary.discounted != 0">
							<tr>
								<td class="text-right" colspan="5">Total</td>
								<td class="so-amount text-right so-border-top">{{ parenthesesValue(part.discount_summary.total) }}</td>
							</tr>
							<tr class="so-discount">
								<td class="text-right so-discount-box text-red" colspan="3">{{ part.discount_summary.name }}</td>
								<td class="text-right text-red" colspan="2">{{ part.discount_summary.discounted > 0 ? 'Add On ' : 'Discounted ' }}</td>
								<td class="so-amount text-right text-red">{{ parenthesesValue(part.discount_summary.discounted) }}</td>
							</tr>
							<tr>
								<td class="text-right" colspan="5">Balance</td>
								<td class="so-amount text-right so-border-top">{{ parenthesesValue(part.discount_summary.balance) }}</td>
							</tr>
						</ng-container>
						-->
					</ng-container>
					<!--
					<tr *ngIf="getTotalDiscount(item.item_parts); let discount" class="so-discount">
						<td colspan="3"></td>
						<td class="text-right text-red" colspan="2">{{ item.name + (discount > 0 ? ' Add On' : ' Discounted') }}</td>
						<td class="so-amount text-right text-red">{{ parenthesesValue(discount) }}</td>
					</tr>
					<ng-container *ngIf="current_version.items.length > 1">
						<tr>
							<td colspan="5" class="text-right"><ng-container *ngIf="item.quantity > 1">{{ 
								item.name + " (" +  parenthesesValue(item.unit_price) + ")" + " x " + item.quantity
							}}</ng-container> Total</td>
							<td class="so-amount text-right so-border-top">{{ parenthesesValue(item.amount - item.discounted_amount) }}</td>
						</tr>
					</ng-container>					
					-->
					<tr *ngIf="current_version.remark">
						<td colspan="2"></td>
						<td class="text-red">{{ current_version.remark }}</td>
						<td colspan="3"></td>
					</tr>
				</tbody>
			</ng-container>
		</table>
	</div>
</div>

<div *ngIf="current_version" class="row" style="border-top: 2px solid #000; padding: 0; margin-bottom: 30px">
	<div class="col-sm-2 col-2" style="padding-left: 23px">
		RINGGIT MALAYSIA
	</div>
	
	<div class="col-sm-6 col-6">
		{{ current_version.amount_words }}
	</div>
	
	<div *ngIf="current_version.discounted_amount || getAllTotalDiscount() else noDiscount" class="col-sm-4 col-4 text-right" style="padding-right: 23px">
		<div class="row">
			<div class="col-6">Total</div>
			<div class="col-sm-2 text-right">RM</div>
			<div class="col-sm-4">
				<p><span style="text-align: right;">{{ parenthesesValue(current_version.amount - getAllTotalDiscount()) }}</span></p>
			</div>
		</div>
		<div *ngIf="current_version.discounted_amount && !current_version.discount_approved" class="row text-red">
			<div class="col-6">
				<a class="btn btn-link btn-success btn-just-icon mark" style="vertical-align: top;
					display: inline;
					margin-right: 30px;
					padding-top: 15px;"><i class="material-icons" (click)="approve()" title="Approve Special Discount">checkmark</i></a>
				<a class="btn btn-link btn-info btn-just-icon edit" style="vertical-align: top;
					display: inline;
					margin-right: 20px;
					padding-top: 15px;" (click)="edit()"><i class="material-icons" title="Edit Special Discount">edit</i></a>
				Special Discount
				<br />
				{{ current_version.discount_approved ? '' : '(Pending Approve) ' }}
			</div>
			<div class="col-sm-2 text-right">RM</div>
			<div class="col-sm-4">
				{{ parenthesesValue(current_version.discounted_amount) }}
			</div>
		</div>
		<div class="row text-red">
			<div class="col-6">
				Discount
			</div>
			<div class="col-sm-2 text-right">RM</div>
			<div class="col-sm-4">
				<p><span style="text-align: right;">{{ parenthesesValue(getAllTotalDiscount() - (current_version.discount_approved ? current_version.discounted_amount : 0)) }}</span></p>
			</div>
		</div>
		<div class="row">
			<div class="col-6">Balance</div>
			<div class="col-sm-2 text-right">RM</div>
			<div class="col-sm-4">
				<p><span style="text-align: right;">{{ parenthesesValue(current_version.amount - (current_version.discount_approved ? current_version.discounted_amount : 0)) }}</span></p>
			</div>
		</div>
	</div>
	<ng-template #noDiscount>
		<div class="col-sm-4 col-4 text-right" style="padding-right: 23px">
			<div class="row">
				<div class="col-6">Total</div>
				<div class="col-sm-2 text-right">RM</div>
				<div class="col-sm-4">
					<p><span style="text-align: right;">{{ parenthesesValue(current_version.amount - getAllTotalDiscount()) }}</span></p>
				</div>
			</div>
		</div>
	</ng-template>
</div>