<div style="background: #fff; background-size: cover; background-position: top center;min-height: 100vh">
	<div class="container">
	  <div class="row">
		<div class="col-10 col-sm-8 ml-auto mr-auto">
			<form #f="ngForm" (ngSubmit)="onSubmit(f)" autocomplete="off">
			  <img src="./assets/img/logo.jpg" class="login-logo">
			  <div class="row">
				<div class="col-sm-12">
					<h3 class="login-title">Forgot Password</h3>
				</div>
				<div class="col-sm-12">
					<p class="login-label">Email</p>
					<input class="input" [class.is-invalid]="errors['email']" type="email" name="email" placeholder="Email Address" ngModel />
			
				    <div class="invalid-feedback" role="alert" *ngIf="errors['email']">
					  {{ errors['email'] }}
				    </div>
				</div>
				<div class="col-sm-4 ml-auto mr-auto">
					<button class="btn-signin">{{ submitting ? 'Processing' : 'Reset My Password' }}</button>
				</div>
			  </div>
			</form>
		</div>
	  </div>
	</div>
</div>