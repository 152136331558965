import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APIService } from './../shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  code;
  errors = {};
  submitting;
  
  constructor(
	private router: Router,
	private aroute: ActivatedRoute,
	private apiService: APIService) { }

  ngOnInit(): void {
	  this.code = this.aroute.snapshot.params['code'];
  }

  onSubmit(f) {
	var data = f.value;
	
	var hasError = false;
	this.errors = [];
	
	if(!data.email) {
		this.errors['email'] = "Email Address is required.";
		hasError = true;
	}
	
	if(!data.password) {
		this.errors['password'] = "New Password is required.";
		hasError = true;
	}
	
	if(!data.password_confirmation) {
		this.errors['password_confirmation'] = "New Password Confirmation is required.";
		hasError = true;
	}
	
	
	if(data.password != data.password_confirmation) {
		this.errors['password_confirmation'] = "New Password Confirmation is not match.";
		hasError = true;
	}
	
	if(hasError || this.submitting) {
		return;
	}
	
	this.submitting = true;
	
	this.apiService.post(APIService.RESET_PASSWORD, { 
		token: this.code,
		email: data.email,
		password: data.password,
		password_confirmation: data.password_confirmation
	})
	.then(() => {
		Swal.fire("Change Password Success", "your password is updated.", "success");
		
		this.router.navigate(['login']);
		this.submitting = false;
			
		f.resetForm();
	}, (err) => {
		this.submitting = false;
		
		if(err.status == 422 && err.error) {
			Object.keys(err.error.errors).forEach(errField => {
				this.errors[errField] = err.error.errors[errField][0];
			});
		} else {
			this.errors['password'] = this.apiService.handleStatusException(err, true);
		}
	});
  }
}
